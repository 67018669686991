import { takeLatest, put, call } from "redux-saga/effects";
import axios from "axios";
import { BASE_URL } from "../../constants/constants";
function* listTransactionsAsync(request) {
  console.log("Enter");
  try {
    const { data } = yield call(() => {
      return axios.request({
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        method: "get",
        url: BASE_URL + `/payment/getAllPayments`,
      });
    });
    yield put({ type: "LIST_TRANSACTIONS__ASYNC", value: data });
    yield put({ type: "LOADING_MEDIA", value: false });
  } catch (e) {
    console.log(e.message);
  }
}
function* listNgoTransactions(request) {
  console.log("Enter");
  try {
    const { data } = yield call(() => {
      return axios.request({
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        method: "get",
        url: BASE_URL + `/payment/getAllPaymentToNGO`,
        params: {
          ngo_id: request.value,
        },
      });
    });
    yield put({ type: "LIST_NGO_TRANSACTIONS_ASYNC", value: data });
    yield put({ type: "LOADING_MEDIA", value: false });
  } catch (e) {
    console.log(e.message);
  }
}
function* listUserTransactions(request) {
  console.log("Enter");
  try {
    const { data } = yield call(() => {
      return axios.request({
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        method: "get",
        url: BASE_URL + `/payment/getAllPaymentByUser`,
        params: {
          user_id: request.value,
        },
      });
    });
    yield put({ type: "LIST_USER_TRANSACTIONS_ASYNC", value: data });
    yield put({ type: "LOADING_MEDIA", value: false });
  } catch (e) {
    console.log(e.message);
  }
}
function* donationRefundAsync(request) {
  try {
    const { data } = yield call(() => {
      return axios.request({
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        method: "post",
        url: BASE_URL + "/payment/refund",
        data: {
          ngo_id: request.value.ngo_id||"",
          payment_id: request.value.payment_id||"",
        },
      });
    });
    yield put({ type: "LOADING_MEDIA", value: false });
  } catch (e) {
    alert("failed");
    console.log(e.message);
  }
}
function* donationSettleAsync(request) {
    console.log("---->",request)
  try {
    
    const { data } = yield call(() => {
      return axios.request({
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        method: "post",
        url: BASE_URL + "/payment/payToNGOs",
        data: {
          ngo_id: request.value.ngo_id||"",
          payment_id: request.value.payment_id||"",
        },
      });
    });
    yield put({ type: "LOADING_MEDIA", value: false });
  } catch (e) {
    alert("failed");
    console.log(e.message);
  }
}
export function* watchTransactions() {
  yield takeLatest("LIST_TRANSACTIONS", listTransactionsAsync);
  yield takeLatest("LIST_NGO_TRANSACTIONS", listNgoTransactions);
  yield takeLatest("LIST_USER_TRANSACTIONS", listUserTransactions);
  yield takeLatest("DONATION_REFUND", donationRefundAsync);
  yield takeLatest("DONATION_SETTLE", donationSettleAsync);
}
