import { BarChart, XAxis, YAxis, Bar } from "recharts";
import { Card } from "@mui/material";
const data = [
  {
    name: "Jan",
    pv: 2400,
  },
  {
    name: "Feb",
    pv: 1398,
  },
  {
    name: "Mar",
    pv: 9800,
  },
  {
    name: "Apr",
    pv: 3908,
  },
  {
    name: "May",
    pv: 4800,
  },
  {
    name: "Jun",
    pv: 3800,
  },
  {
    name: "July",
    pv: 4300,
  },
  {
    name: "Aug",
    pv: 4300,
  },
  {
    name: "Sep",
    pv: 4300,
  },
  {
    name: "Oct",
    pv: 4300,
  },
  {
    name: "Nov",
    pv: 4300,
  },
  {
    name: "Dec",
    pv: 4300,
  },
];

const BarChartGraph = () => {
  return (
    <Card
      sx={{
        display: "inline-flex",
        borderRadius: "12px",
        border: "1px solid #DCDCEB",
        padding: "5% 1% 5% 1%",
      }}
    >
      <BarChart
        width={460}
        height={200}
        data={data}
      >
        {/* <CartesianGrid strokeDasharray="1" horizontal={true} vertical={false} /> */}
        <XAxis axisLine={false} type="category" dataKey="name" />
        <YAxis axisLine={false} />
        {/* <Tooltip /> */}
        {/* <Legend /> */}
        <Bar barSize={30} dataKey="pv" fill="#FAC11C" />
      </BarChart>
    </Card>
  );
};
export default BarChartGraph;
