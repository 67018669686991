import { useLocation, useNavigate } from "react-router";
import ngoImage from "../../assets/images/profile-image";
import "./profileContainer.scss";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import backButton from "../../assets/images/back.svg";

const ProfileContainer = (props) => {
  const location = useLocation();

  const [edit, setEdit] = useState(true);

  const [userData, setUserData] = useState({
    name: location.state.ngoDetail.name,
    id: location.state.ngoDetail._id,
    email: location.state.ngoDetail.emailAddress,
    address: location.state.ngoDetail.address,
    city: location.state.ngoDetail.city,
    state: location.state.ngoDetail.state,
    contactNumber: location.state.ngoDetail.contactNumber,
    registrationNumber: location.state.ngoDetail.registrationNumber,
    password: location.state.ngoDetail.password,
    status: location.state.ngoDetail.status,
    profilePic: location.state.ngoDetail.profilePic,
  });
  const navigate = useNavigate();
  const editNgo = () => {
    setEdit(!edit);
    if (!edit) {
      props.editNgo(userData);
      const timer = setTimeout(() => {
        alert("Edited successfull");
        props.listAllNgo();
        navigate("/dashboard/details", { state: { header: "Total N.G.O.s" } });
      }, 200);
      return () => clearTimeout(timer);
     
    }
  };
  const handleApprove = (action) => {
    userData.action = action;
    setUserData(userData.action);
    props.approveNgo(userData);
    props.listAllNgo();
    const timer = setTimeout(() => {
      alert("Approved successfull");
      navigate("/dashboard/details", { state: { header: "Total N.G.O.s" } });
    }, 200);
    return () => clearTimeout(timer);
  };
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/login");
    }
  }, [navigate, props]);
  const handleChange = (event, formFields) => {
    setUserData((prevState) => ({
      ...prevState,
      [formFields]: event.target.value,
    }));
  };
  return (
    <div className="outer-container">
      <div className="project-form">
        <div>
          <img
            className="ngo-text"
            onClick={() => {
              navigate("/dashboard/details", {
                state: { header: "Total N.G.O.s" },
              });
            }}
            style={{ cursor: "pointer" }}
            src={backButton}
            alt={backButton}
          />
          <span style={{ cursor: "default" }} className="ngo-text">
            N.G.O. DETAILS
          </span>
          <span className="NGO">
            <button
              onClick={() => {
                editNgo();
              }}
              className="profile-approve"
            >
              {edit ? "EDIT" : "SAVE"}
            </button>
            {userData.status === "pending" ? (
              <button
                onClick={() => {
                  handleApprove("approve");
                }}
                className="profile-approve"
              >
                APPROVE
              </button>
            ) : (
              ""
            )}
            <button
              onClick={() => {
                handleApprove("block");
              }}
              className="profile-approve"
            >
              BLOCK
            </button>
            <button
              onClick={() => {
                navigate("/dashboard/details", {
                  state: { header: "Total N.G.O.s" },
                });
              }}
              className="profile-approve"
            >
              CANCEL
            </button>
          </span>
          <div style={{ marginTop: "3vh", marginLeft: "6vw" }}>
            <img
              style={{ width: "150px", height: "150px", borderRadius: "100%" }}
              src={userData.profilePic || ngoImage}
              alt={userData.profilePic || ngoImage}
            />
            <table className="profile-table">
              <tr>
                <td className="label-profile-table" style={{ columnSpan: "2" }}>
                  Name*
                </td>
              </tr>
              <tr>
                <td style={{ columnSpan: "2" }}>
                  <input
                    onChange={(e) => {
                      handleChange(e, "name");
                    }}
                    disabled={edit}
                    value={userData.name}
                    className="input-profile-table"
                  />
                </td>
              </tr>
            </table>
            <table className="profile-table" style={{ marginTop: "3vh" }}>
              <tr>
                <td className="label-profile-table">Email Address*</td>
                <td className="label-profile-table"> Address*</td>
              </tr>
              <tr>
                <td>
                  <input
                    onChange={(e) => {
                      handleChange(e, "email");
                    }}
                    disabled={edit}
                    value={userData.email}
                    className="profile-input"
                  />
                </td>
                <td>
                  <input
                    onChange={(e) => {
                      handleChange(e, "address");
                    }}
                    disabled={edit}
                    value={userData.address}
                    className="profile-input"
                  />
                </td>
              </tr>

              <tr>
                <td className="label-profile-table">Contact Number*</td>
                <td className="label-profile-table"> Registration Number*</td>
              </tr>
              <tr>
                <td>
                  <input
                    onChange={(e) => {
                      handleChange(e, "contactNumber");
                    }}
                    disabled={edit}
                    value={userData.contactNumber}
                    className="profile-input"
                  />
                </td>
                <td>
                  <input
                    onChange={(e) => {
                      handleChange(e, "registrationNumber");
                    }}
                    disabled={edit}
                    value={userData.registrationNumber}
                    className="profile-input"
                  />
                </td>
              </tr>

              <tr>
                <td className="label-profile-table">City*</td>
                <td className="label-profile-table"> State*</td>
              </tr>
              <tr>
                <td>
                  <input
                    onChange={(e) => {
                      handleChange(e, "city");
                    }}
                    disabled={edit}
                    value={userData.city}
                    className="profile-input"
                  />
                </td>
                <td>
                  <input
                    onChange={(e) => {
                      handleChange(e, "state");
                    }}
                    disabled={edit}
                    value={userData.state}
                    className="profile-input"
                  />
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    userData: state.usersReducer.results,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    approveNgo: (userData) =>
      dispatch({ type: "APPROVE_NGO", value: userData }),
    listAllNgo: () => dispatch({ type: "NGO_LIST" }),
    editNgo: (userData) => dispatch({ type: "EDIT_NGO", value: userData }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer);
