const initialState = {
    results:{}
  };
  
  const loginReducer = (state = initialState, action) => {
    const newState = { ...state };
  
    switch (action.type) {
        
      case "LOGINASYNC":
        newState.results = action.value;
        break;
      default:
        return newState;
    }
    return newState;
  };
  
  export default loginReducer;
  