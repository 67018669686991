import React from 'react';
import Header from '../../components/header/header';
import Navbar from '../../components/navbar/navbar';
import Lottie from 'react-lottie';
import pagenotfound from '../../assets/images/pagenotfound.json';
class BankDetails extends React.Component {
    render(){
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: pagenotfound,
            renderer: 'svg'
        }
        return(
            <div>
                <Navbar  active="Bank Details"/>
                <Header heading="Bank Details"/>
                <Lottie
                options={defaultOptions}
                height={500}
                width={500}
               />
            </div>
        );
    }

}
export default BankDetails;
