const initialState = {
  allDonations: [],
  ngoDonations: [],
  donorDonations:[],
};

const transactionsReducer = (state = initialState, action) => {
  const newState = { ...state };

  switch (action.type) {
    case "LIST_TRANSACTIONS__ASYNC":
      newState.allDonations = action.value;
      break;
    case "LIST_NGO_TRANSACTIONS_ASYNC":
      newState.ngoDonations = action.value;
      break;
      case "LIST_USER_TRANSACTIONS_ASYNC":
      newState.donorDonations = action.value;
      break;
    default:
      return newState;
  }
  return newState;
};
export default transactionsReducer;
