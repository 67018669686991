import React, { useEffect, useState } from "react";
import Header from "../../../components/header/header";
import Navbar from "../../../components/navbar/navbar";
import DashboardDetailsContainer from "../../../components/dashboardDetailsContainer/dashboardDetailsContainer";
import { useLocation } from "react-router-dom";
import gifReader from "../../../assets/images/loading.json";
import Lottie from "react-lottie";
import { Card } from "@mui/material";
import { connect } from "react-redux";
let dashboardData;
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: gifReader,
  renderer: "svg",
};

const DashboardDetails = (props) => {
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const handleChange = (event, value) => {
    setPage(value);
    props.loadingMedia(true);
    if (props.actionResponse === "Total Projects") {
      props.listAllProjects(value);
    }
    if (props.actionResponse === "Total N.G.O.s") {
      props.listAllNgo(value);
    }
    if (props.actionResponse === "Total Donors" || props.donorTab) {
      props.listAllDonors(value);
    }
  };
  const location = useLocation();
  if (props.actionResponse === "Total Projects") {
    dashboardData = props.projectListResponse.results;
  }
  if (props.actionResponse === "Total N.G.O.s") {
    dashboardData = props.ngoListResponse.results;
  }
  if (props.actionResponse === "Total Donors" || props.donorTab) {
    dashboardData = props.donorListResponse.results;
  }

  useEffect(() => {
    if (!props.loginResponse.results) {
      props.auth();
    } else {
      if (props.selectedResponse === "Overall") {
        setCount(props.projectListResponse.totalCount);
      }
      if (props.selectedResponse === "Completed") {
        setCount(props.loginResponse.results.allBlogStatus.completed);
      }
      if (props.selectedResponse === "In progress") {
        setCount(props.loginResponse.results.allBlogStatus.inProgress);
      } else if (props.selectedResponse === "Closing") {
        setCount(props.loginResponse.results.allBlogStatus.closing);
      } else if (props.selectedResponse === "Rejected") {
        setCount(props.loginResponse.results.allBlogStatus.rejected);
      }
    }
    if (!props.selectedResponse) {
      props.selectedTab(location.state && location.state.selectedValue);
    }

    if (!props.actionResponse) {
      props.dashboardAction(
        (location.state && location.state.header) || "Total Donors"
      );
    }
    console.log("Action respnse", props.actionResponse);
    if (!props.projectTabResponse) {
      props.projectTabAction(location.state && location.state.tabValue);
    }
    if (props.actionResponse === "Total Projects") {
      if (!props.projectListResponse.results) {
        props.loadingMedia(true);
        props.listAllProjects(1);
      }
    }
    if (props.actionResponse === "Total N.G.O.s") {
      if (!props.ngoListResponse.results) {
        props.loadingMedia(true);
        props.listAllNgo(1);
      }
      setCount(props.ngoListResponse.totalNGOUsers);
    }
    if (props.actionResponse === "Total Donors" || props.donorTab) {
      if (!props.donorListResponse.results) {
        props.dashboardAction("Total Donors");
        props.loadingMedia(true);
        props.listAllDonors(1);
        setCount(props.donorListResponse.totalDonorUsers);
      }
      if (props.donorTab && props.actionResponse !== "Total N.G.O.s") {
        props.dashboardAction("Total Donors");
        setCount(props.donorListResponse.totalDonorUsers);
      }
      if (props.actionResponse === "Total Donors") {
        setCount(props.donorListResponse.totalDonorUsers);
      }
      if (props.actionResponse === "Total N.G.O.s") {
        setCount(props.ngoListResponse.totalNGOUsers);
      }
    }
  }, [props, location]);
  return (
    <React.Fragment>
      <Navbar active={props.active || "Dashboard"} />
      <Header
        heading={
          location.state && location.state.header === "Total Projects"
            ? "HOME/DASHBOARD"
            : "NGO & DONOR"
        }
      />
      <Card
        sx={{
          margin: "0% 0% 0% 11.7%",
          padding: "3% 2% 9% 4%",
          border: "1px solid #E5E5E5",
          borderRadius: "15px",
        }}
      >
        {dashboardData ? (
          <React.Fragment>
            {props.loadingMediaResponse ? (
              <Lottie options={defaultOptions} height={500} width={500} />
            ) : (
              <DashboardDetailsContainer
                donorTab={props.donorTab}
                dashboardData={dashboardData}
                handleChange={handleChange}
                page={page}
                setPage={setPage}
                count={count}
                setCount={setCount}
              />
            )}
          </React.Fragment>
        ) : (
          <Lottie options={defaultOptions} height={500} width={500} />
        )}
      </Card>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    projectListResponse: state.projectReducer.results,
    ngoListResponse: state.usersReducer.results,
    donorListResponse: state.usersReducer.donors,
    loadingMediaResponse: state.projectReducer.loadingMedia,
    actionResponse: state.projectReducer.dashboardAction,
    projectTabResponse: state.projectReducer.projectTab,
    selectedResponse: state.projectReducer.selectedTab,
    loginResponse: state.loginReducer.results,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    listAllProjects: (page) => dispatch({ type: "PROJECT_LIST", value: page }),
    listAllNgo: (page) => dispatch({ type: "NGO_LIST", value: page }),
    listAllDonors: (page) => dispatch({ type: "DONOR_LIST", value: page }),
    loadingMedia: (loader) =>
      dispatch({ type: "LOADING_MEDIA", value: loader }),
    dashboardAction: (action) =>
      dispatch({ type: "DASHBOARD_ACTION", value: action }),
    projectTabAction: (action) =>
      dispatch({ type: "PROJECT_TAB", value: action }),
    selectedTab: (action) => dispatch({ type: "SELECTED_TAB", value: action }),
    auth: () => dispatch({ type: "AUTH" }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DashboardDetails);
