import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import store from "./redux/store";
import { defineCustomElements } from "@duetds/date-picker/dist/loader";
import { StyledEngineProvider } from '@mui/material/styles';
import { render } from "react-dom"; 
defineCustomElements(window);
//const root = ReactDOM.createRoot(document.getElementById("root"));
render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <Provider store={store}>
        <App />
      </Provider>
    </StyledEngineProvider>
  </React.StrictMode>,
  document.getElementById("root")
);