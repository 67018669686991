import Navbar from "../../../components/navbar/navbar";
import Header from "../../../components/header/header";
import React from "react";
import ProjectForm from "../../../components/projectForm/projectForm";
import { connect } from "react-redux";
import {Card} from "@mui/material"

class CreateProject extends React.Component {
  constructor(props) {
    super(props);
    if (!this.props.loginResponse.results) {
      this.props.auth();
    }
    this.state = {
      title: "",
      description: "",
      amountNeeded: "",
      cause: "",
      donationDetails: "",
      videoMedia: [],
      imageMedia: [],
      audioMedia: [],
      otherMedia: [],
      startDate: new Date(),
      endDate: new Date(),
    };
  }
  render() {
    return (
      <div>
        <Navbar active="Projects" />
        <Header heading="HOME/PROJECTS" />
        <Card
          sx={{
            margin: "0% 0% 0% 11.7%",
            padding: "0% 3% 9% 5%",
            border: "1px solid #E5E5E5",
            borderRadius: "15px",
          }}
        >
          <ProjectForm action="Create a Project" {...this.state} />
        </Card>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginResponse: state.loginReducer.results,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    listAllProjects: () => dispatch({ type: "PROJECT_LIST" }),
    auth: () => dispatch({ type: "AUTH" })
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateProject);
