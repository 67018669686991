import {
    Box,
    Card,
    TableCell,
    TableRow,
    TableHead,
    Paper,
    Table,
    TableContainer,
    TableBody,
    ListItemIcon,
  } from "@mui/material";
  import { donorDonationHeading } from "../../constants/constants";
  import { useNavigate } from "react-router-dom";
  
  import { connect } from "react-redux";
  import { useEffect } from "react";
  import refundImage from "../../assets/images/refund.png";
  import approveImage from "../../assets/images/approve-image.png";
  import DeleteImage from "../../assets/images/delete-image.png";
  import DialogBox from "../dialogBox/confirmationDialog";
  import exportImage from "../../assets/images/export.png";
  import rejectImage from "../../assets/images/reject-image.png";
  import Menu from "@mui/material/Menu";
  import MenuItem from "@mui/material/MenuItem";
  import more from "../../assets/images/more.png";
  import { useState, React } from "react";
  
  import block from "../../assets/images/block.png";
  import {
    rejectBlogMessage,
    approveBlogMessage,
    deleteProjectMessage,
    colorChange,
  } from "../../constants/constants";
  let status = "";
  let title = "";
  let image = "";
  const DonorTransaction = (props) => {
    const navigate = useNavigate();
    const [feedback, setFeedback] = useState("");
    const [modal, setModal] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const handleDelete = () => {
      status = "";
      if (modal) {
        setModal(!modal);
        props.deleteProject();
        let timer = setTimeout(() => {
          props.listAllProjects();
          navigate("/projects");
        }, 200);
        return () => clearTimeout(timer);
      } else {
        image = DeleteImage;
        setModal(!modal);
        title = deleteProjectMessage;
      }
    };
    const projectStatusChange = (action) => {
      if (modal) {
        setModal(!modal);
        props.projectStatusChange();
        let timer = setTimeout(() => {
          props.listAllProjects();
        }, 200);
        return () => clearTimeout(timer);
      } else {
        if (action === "approved") {
          image = approveImage;
          title = approveBlogMessage;
        } else {
          image = rejectImage;
          title = rejectBlogMessage;
        }
        status = action;
        setModal(!modal);
      }
    };
    useEffect(() => {
      props.listUserTransactions(props.data._id);
    }, []);
    return (
      <TableContainer sx={{marginBottom:"10vh"}} component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow sx={{ background: "#F5F5F5" }}>
              {donorDonationHeading &&
                donorDonationHeading.map((header, index) => {
                  return (
                    <TableCell
                      align="left"
                      sx={{ fontSize: "0.9vw" }}
                      key={index}
                    >
                      {header}
                    </TableCell>
                  );
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.donorTransactionResponse.results
              ? props.donorTransactionResponse.results.map((data, index) => {
                  return (
                    <TableRow
                      key={data.title}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <DialogBox
                        status={status}
                        feedback={feedback}
                        setFeedback={setFeedback}
                        submit={
                          status !== "" ? projectStatusChange : handleDelete
                        }
                        handleDelete={handleDelete}
                        image={image}
                        title={title}
                        modal={modal}
                        setModal={setModal}
                      />
                     <TableCell
                        sx={{ textTransform: "uppercase", fontSize: "0.9vw" }}
                        align="left"
                      >
                        <strong>{data.blog_title}</strong>
                      </TableCell>
                      <TableCell
                        sx={{ textTransform: "uppercase", fontSize: "0.9vw" }}
                        align="left"
                      >
                        <strong>{data.amount}</strong>
                      </TableCell>
                      <TableCell
                        sx={{ textTransform: "uppercase", fontSize: "0.9vw" }}
                        align="left"
                      >
                        <strong>{data.createdDate}</strong>
                      </TableCell>
                      
                      {/* <TableCell
                        sx={{ textTransform: "uppercase", fontSize: "0.9vw" }}
                        align="left"
                      >
                        <strong>G PAY</strong>
                      </TableCell> */}
                      {/* <TableCell
                        sx={{ textTransform: "uppercase", fontSize: "0.9vw" }}
                        align="left"
                      >
                        <Box
                          sx={{
                            background: colorChange(data.status)[0],
                            borderRadius: "30px",
                            textTransform: "uppercase",
                            color: "#ffffff",
                            textAlign: "center",
                            padding: "3% 0% 3% 0%",
                            alignItems: "center",
                            display: "flex",
                            width: "9vw",
                          }}
                        >
                          <img
                            style={{
                              borderRadius: "100%",
                              marginRight: "5%",
                              marginLeft: "5%",
                            }}
                            src={colorChange(data.status)[1]}
                            alt={colorChange(data.status)[1]}
                          />
                          {data.status}
                        </Box>
                      </TableCell> */}
  
                      <TableCell align="left">
                        <img
                          style={{ cursor: "pointer" }}
                          onClick={handleClick}
                          src={more}
                          alt={more}
                        />
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          autoFocus={false}
                          sx={{boxShadow:"none"}}
                        >
                          <MenuItem
                            onClick={() => {
                              projectStatusChange("block");
                              handleClose();
                            }}
                          >
                            <ListItemIcon>
                              <img src={refundImage} alt={refundImage} />
                            </ListItemIcon>
                            Refund
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              setAnchorEl(null);
                              handleDelete();
                            }}
                          >
                            <ListItemIcon>
                              <img src={exportImage} alt={exportImage} />
                            </ListItemIcon>
                            Export
                          </MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  );
                })
              : ""}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };
  const mapStateToProps = (state) => {
    return {
      donorTransactionResponse: state.transactionsReducer.donorDonations,
    };
  };
  const mapDispatchToProps = (dispatch) => {
    return {
      listUserTransactions: (id) =>
        dispatch({ type: "LIST_USER_TRANSACTIONS", value: id }),
    };
  };
  export default connect(mapStateToProps, mapDispatchToProps)(DonorTransaction);
  