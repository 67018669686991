import React from 'react';
import Header from '../../components/header/header';
import Navbar from '../../components/navbar/navbar';
import ProfileContainer from '../../components/profileContainer/profileContainer';
class Profile extends React.Component {
    render(){
        return(
            <div>
                <Navbar  active="Dashboard"/>
                <Header heading="HOME/NGO LIST/NGO DETAILS"/>
                <ProfileContainer/>
            </div>
        );
    }

}
export default Profile;