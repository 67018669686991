import Header from "../../../components/header/header";
import React from "react";
import Navbar from "../../../components/navbar/navbar";
import { connect } from "react-redux";
import { Card } from "@mui/material";

import ProjectDetailsContainer from "../../../components/projectDetailsContainer/projectDetailsContainer";

class ProjectDetails extends React.Component {
  constructor(props) {
    super(props);
    if (!this.props.loginResponse.results) {
      this.props.auth();
    }
  }
  render() {
    return (
      <div>
        <Navbar active="Projects" />
        <Header heading="HOME/PROJECTS" />
        <Card
          sx={{
            margin: "0% 0% 0% 11.8%",
            padding: "0% 3% 9% 3%",
            border: "1px solid #E5E5E5",
            borderRadius: "15px",
          }}
        >
          <ProjectDetailsContainer />
        </Card>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginResponse: state.loginReducer.results,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    auth: () => dispatch({ type: "AUTH" }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetails);
