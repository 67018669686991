import React from "react";
import "./navbar.scss";
import {connect} from "react-redux"
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo3.png";
import Dashboard from "../../assets/images/NavigationBar/Dashboard.png";
import Reward from "../../assets/images/NavigationBar/Reward.png";
import UserList from "../../assets/images/NavigationBar/UserList.png";
import Projects from "../../assets/images/NavigationBar/Projects.png";
import DOnationHistory from "../../assets/images/NavigationBar/DOnationHistory.png";

import News from "../../assets/images/NavigationBar/News.png";
import PledgeList from "../../assets/images/NavigationBar/PledgeList.png";
import MediaLib from "../../assets/images/NavigationBar/MediaLib.png";
import BankDet from "../../assets/images/NavigationBar/BankDet.png";
import logout from "../../assets/images/Logout.png"

const navigations = [
  ["Dashboard",Dashboard],
 ["Rewards",Reward],
 ['User List',UserList],
 ["Projects",Projects],
 ['Donation History',DOnationHistory],
 ["News Articles",News],
 ['Pledged List',PledgeList],
 ["Media Library",MediaLib],
 ["Bank Details",BankDet],
 ["Log Out",logout],
];

const Navbar = (props) => {
  const navigate = useNavigate();
  const logOut = () => {
    props.logOut();
    localStorage.removeItem("token");
    navigate("/login");
  };
  const handleChange = (slug) => {
    navigate("/" + slug);
  };
  return (
    <div className="navbar-container">
      <img
         className="image-logo-3"
         alt={logo}
         src={logo}> 
        </img>
      <div className="navbar">
        {navigations.map((slug, index) => {
          return (
            
            <div
              className={
                props.active === slug[0] ? "nav-links-active" : "nav-links"
              }
              key={index}
              onClick={() => {
                slug[0] === "Log Out" ? logOut():
                handleChange(slug[0].toLowerCase().replace(/\s/g, ''));
              }}
            > <img src={slug[1]} style={{marginRight:"0.3vw"}} alt=""></img>
            
              {slug[0]}
            </div>
          );
        })}
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    logOut: () => dispatch({ type: "LOGOUT" }),
  };
};
export default connect(null, mapDispatchToProps)(Navbar);

