import Box from "@mui/material/Box";
import backButton from "../../assets/images/back.svg";
import Typography from "@mui/material/Typography";
import phoneIcon from "../../assets/images/phone.png";
import { Button } from "@mui/material";
import DialogBox from "../dialogBox/confirmationDialog";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import approveImage from "../../assets/images/approve-image.png";
import {
  deleteProjectMessage,
  CreateProjectMessage,
  EditProjectMessage,
} from "../../constants/constants";
import {connect} from "react-redux";
let status = "";
let title = "";
let image = "";
const Preview = ({ projectFields, preview, setPreview, createdProject, type, loadingMedia }) => {
  console.log("bid", createdProject);
  const [modal, setModal] = useState(false);
  const navigate = useNavigate();
  const handleSubmit = (event) => {
    event.preventDefault();
    if (modal) {
      if (projectFields) {
        setModal(!modal);
        navigate(`/${type || "Projects"}`);
        loadingMedia(true)
        loadingMedia(false)
      } else {
        
        setModal(!modal);
        navigate(`/${type || "Projects"}`);
        loadingMedia(true)
        loadingMedia(false)
      }
    } else {
      setModal(!modal);
      title = projectFields ? EditProjectMessage : CreateProjectMessage;
      image = approveImage;
      status = "approve";
    }
  };
  return (
    <Box>
      <DialogBox
        status={status}
        submit={status !== "" ? handleSubmit : ""}
        image={image}
        title={title}
        modal={modal}
        setModal={setModal}
      />
      <form
        onSubmit={(e) => {
          handleSubmit(e);
        }}
        className="project-header3 left"
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <img
            className="back-button"
            onClick={() => {
              setPreview(false);
              navigate(`/${type || "projects"}/create`, {
                state: { editprojectDetails: createdProject },
              });
            }}
            style={{ cursor: "pointer", height: "2vh", width: "1.2vw" }}
            src={backButton}
            alt={backButton}
          />
          <Typography sx={{ fontSize: "1.25vw", fontWeight: "bold" }}>
            {projectFields
              ? `Edit ${type || "Project"} / Preview`
              : `Create ${type || "Project"} / Preview`}
          </Typography>
        </Box>
        <div style={{ height: "100%", width: "100%" }}>
          <img
            style={{ margin: "5% 0% 5% 30%", width: "45%" }}
            src={phoneIcon}
            alt={phoneIcon}
          />
          <iframe
            style={{
              position: "absolute",
              left: "46.4%",
              top: "37%",
              border: "none",
              borderRadius: "12px",
              height: "78%",
              width: "19.6%",
            }}
            src={
              "https://ekj-webviewtest.web.app/#/" +
              localStorage.getItem('previewId')
            }
          ></iframe>
          <button
            type="submit"
            style={{ width: "18vw", marginLeft: "27vw" }}
            className="button-post"
          >
            {projectFields ? "Save" : "Post"}
          </button>
        </div>
      </form>
    </Box>
  );
};
const mapStateToProps = (state) => {
  return {
    loadingMediaResponse: state.projectReducer.loadingMedia,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadingMedia: (loader) =>
      dispatch({ type: "LOADING_MEDIA", value: loader }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Preview);

