import React, { useState, useEffect } from "react";
import Lottie from "react-lottie";
import spinner from "../../assets/images/loadingSpinner.json";
import uploadImg from "../../assets/images/uploadImg.png";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import backButton from "../../assets/images/back.svg";
import { Card, Typography, Box } from "@mui/material";
import ConfirmationDialog from "../dialogBox/confirmationDialog";
import closeIcon from "../../assets/images/close.png";
import deleteIcon from "../../assets/images/delete-image.png";
import approveImage from "../../assets/images/approve-image.png";
import gifReader from "../../assets/images/loading.json";
import { Document, Page, pdfjs } from "react-pdf";

import {
  deleteProjectMessage,
  CreateProjectMessage,
  UpdateProjectMessage,
  EditProjectMessage,
} from "../../constants/constants";
import UpdateImpactPreview from "../preview/updateImpactPreview";
let title = "";
let image = "";
let status = "";
let urlMedia = "";
let upload1Type = "";
let createdResponse = {};
const pageloaderOptions = {
  loop: true,
  autoplay: true,
  animationData: gifReader,
  renderer: "svg",
};
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: spinner,
  renderer: "svg",
};
document.addEventListener("contextmenu", (event) => {
  event.preventDefault();
});
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
window.Buffer = window.Buffer || require("buffer").Buffer;

const UpdateOrImpactForm = (props) => {
  const navigate = useNavigate();
  const [preview, setPreview] = useState(false);
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/login");
    }
    if (props.removeMediaResponse.results) {
      setUpdateOrImpactFields(props.removeMediaResponse.results);
      props.removeUploadContent();
    }
    if (props.projectDetailsResponse.results) {
      createdResponse = props.projectDetailsResponse.results;
      props.listAllProjects();
      setPreview(true);
    }
    if (props.upload1Response.Location) {
      if (upload1Type.match("image")) {
        let newObject = { ...updateOrImpactFields };
        upload1Type = "";
        newObject.imageUpdate.push(props.upload1Response.Location);
        setUpdateOrImpactFields(newObject);
      }
      if (upload1Type.match("video")) {
        let newObject = { ...updateOrImpactFields };
        upload1Type = "";
        newObject.videoUpdate.push(props.upload1Response.Location);
        setUpdateOrImpactFields(newObject);
      }
      if (upload1Type.match("audio")) {
        upload1Type = "";
        let newObject = { ...updateOrImpactFields };
        newObject.audioUpdate.push(props.upload1Response.Location);
        setUpdateOrImpactFields(newObject);
      }
      props.removeUploadContent();
    }
  }, [navigate, props]);
  const location = useLocation();
  const [modal, setModal] = useState(false);
  const { projectDetails, updateFields } = location.state;
  console.log(location.state,"----------->",projectDetails)
  const [updateOrImpactFields, setUpdateOrImpactFields] = useState({
    title: updateFields ? updateFields.title : "",
    lastUpdate: updateFields ? updateFields.lastUpdate : "",
    imageUpdate: updateFields ? updateFields.imageUpdate : [],
    videoUpdate: updateFields ? updateFields.videoUpdate : [],
    audioUpdate: updateFields ? updateFields.audioUpdate : [],
    otherUpdate: updateFields ? updateFields.otherUpdate : [],
    _id: updateFields ? updateFields._id : "",
  });
  const handleDelete = (url, type) => {
    if (modal) {
      if (status === "image") {
        let media = {
          src: urlMedia,
          type: status,
          bId: updateOrImpactFields._id,
          documentType: props.type,
        };

        props.removeMedia(media);
        const timer = setTimeout(() => {
          props.listAllProjects();
          setModal(!modal);
        }, 200);
        return () => clearTimeout(timer);
      }
      if (status === "video") {
        let media = {
          src: urlMedia,
          type: status,
          bId: updateOrImpactFields._id,
          documentType: props.action,
        };
        props.removeMedia(media);
        const timer = setTimeout(() => {
          props.listAllProjects();
          setModal(!modal);
        }, 200);
        return () => clearTimeout(timer);
      }
      if (status === "audio") {
        let media = {
          src: urlMedia,
          type: status,
          bId: updateOrImpactFields._id,
          documentType: props.action,
        };
        props.removeMedia(media);
        const timer = setTimeout(() => {
          props.listAllProjects();
          setModal(!modal);
        }, 200);
        return () => clearTimeout(timer);
      }
      if (status === "file") {
        let media = {
          src: urlMedia,
          type: status,
          bId: updateOrImpactFields._id,
          documentType: props.action,
        };
        props.removeMedia(media);
        const timer = setTimeout(() => {
          props.listAllProjects();
          setModal(!modal);
        }, 200);
        return () => clearTimeout(timer);
      }
    } else {
      urlMedia = url;
      title = deleteProjectMessage;
      image = deleteIcon;
      status = type;
      setModal(!modal);
    }
  };
  const handleUpload = (event, upload) => {
    props.loadingMedia(true);
    upload1Type = event.target.files[0].type;
    props.upload1(event.target.files[0]);
  };
  const handleChange = (event, formFields) => {
    event.preventDefault();
    setUpdateOrImpactFields((prevState) => ({
      ...prevState,
      [formFields]: event.target.value,
    }));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (location.state && location.state.updateFields) {
      localStorage.setItem("previewId", projectDetails.b_id);
      updateOrImpactFields.type = props.type;
      updateOrImpactFields.b_id = projectDetails.b_id;
      setUpdateOrImpactFields(updateOrImpactFields);
      props.editImpactOrUpdate(updateOrImpactFields);
      createdResponse.blog = projectDetails;
      createdResponse.update = updateFields;
      setPreview(true);
    } else {
      localStorage.setItem("previewId", projectDetails.b_id);
      updateOrImpactFields.type = props.type;
      updateOrImpactFields.b_id = projectDetails.b_id;
      setUpdateOrImpactFields(updateOrImpactFields);
      props.projectUpdate(updateOrImpactFields);
    }
  };

  return (
    <Card
      sx={{
        borderRadius: "21px",
        border: "1px solid #EBEBEB",
        margin: "3% 2% 1% 0%",
      }}
    >
      {props.loadingMediaResponse && upload1Type === "" ? (
        <Lottie options={pageloaderOptions} height={500} width={500} />
      ) : preview ? (
        <UpdateImpactPreview
          preview={preview}
          setPreview={setPreview}
          projectFields={location.state && location.state.editprojectDetails}
          createdProject={createdResponse}
          heading={props.type}
        />
      ) : (
        <form
          className="project-header3 left"
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img
              className="back-button"
              onClick={() => {
                navigate("/projects/" + projectDetails.b_id, {
                  state: { projectDetails: projectDetails },
                });
              }}
              style={{ cursor: "pointer", height: "2vh", width: "1.2vw" }}
              src={backButton}
              alt={backButton}
            />
            <Typography sx={{ fontSize: "1.25vw" }}>{props.heading}</Typography>
          </Box>

          <div className="inline">
            <div>
              <div className="title-input">Title</div>
              <textarea
                onChange={(e) => {
                  handleChange(e, "title");
                }}
                value={updateOrImpactFields.title || ""}
                maxLength={180}
                className="textarea-input"
                type="text"
                rows={4}
                placeholder="Type something"
                required
              />
              <div className="title-input">Description</div>
              <textarea
                rows={6}
                className="textarea-input"
                onChange={(e) => {
                  handleChange(e, "lastUpdate");
                }}
                value={updateOrImpactFields.lastUpdate || ""}
                maxLength={360}
                placeholder="Type something"
              />
              <div className="uploadFile">
                {props.loadingMediaResponse && upload1Type !== "" ? (
                  <Lottie options={defaultOptions} height={50} width={50} />
                ) : (
                  <img
                    className="upload"
                    src={uploadImg}
                    alt={uploadImg}
                    style={{ border: "0px" }}
                  />
                )}
                <input
                  placeholder="browse"
                  id="inputTag"
                  type="file"
                  className=""
                  onChange={(event) => {
                    handleUpload(event, "upload1");
                  }}
                />
              </div>
              {updateOrImpactFields.imageUpdate &&
                updateOrImpactFields.imageUpdate.map((image, index) => {
                  return (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{ width: "30%", height: "100px", marginTop: "5%" }}
                      >
                        <img
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                          src={image}
                          alt={image}
                        />
                      </Box>
                      <img
                        onClick={() => {
                          handleDelete(image, "image");
                        }}
                        style={{ cursor: "pointer" }}
                        height="15vh"
                        src={closeIcon}
                        alt={closeIcon}
                      />
                    </Box>
                  );
                })}
              {updateOrImpactFields.videoUpdate &&
                updateOrImpactFields.videoUpdate.map((video, index) => {
                  return (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{ width: "30%", height: "100px", marginTop: "5%" }}
                      >
                        <video
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                          controls
                          src={video}
                          alt={video}
                        />
                      </Box>
                      <img
                        onClick={() => {
                          handleDelete(video, "video");
                        }}
                        style={{ cursor: "pointer" }}
                        height="15vh"
                        src={closeIcon}
                        alt={closeIcon}
                      />
                    </Box>
                  );
                })}
              {updateOrImpactFields.audioUpdate &&
                updateOrImpactFields.audioUpdate.map((audio, index) => {
                  return (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{ width: "30%", height: "100px", marginTop: "5%" }}
                      >
                        <audio
                          controls
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                          src={audio}
                          alt={audio}
                        />
                      </Box>
                      <img
                        onClick={() => {
                          handleDelete(audio, "audio");
                        }}
                        style={{ cursor: "pointer" }}
                        height="15vh"
                        src={closeIcon}
                        alt={closeIcon}
                      />
                    </Box>
                  );
                })}
              {updateOrImpactFields.otherUpdate &&
                updateOrImpactFields.otherUpdate.map((file, index) => {
                  return (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: "20vh",
                      }}
                    >
                      <Box
                        sx={{
                          width: "30%",
                          height: "100px",
                          marginTop: "5%",
                          marginBottom: "15%",
                        }}
                      >
                        <Document renderMode="canvas" file={file}>
                          <Page
                            className="react-pdf__Page__canvas margin-bottom"
                            height={300}
                            canvasBackground="#ffff"
                            pageNumber={1}
                          />
                        </Document>
                      </Box>
                      <img
                        onClick={() => {
                          handleDelete(file, "file");
                        }}
                        style={{ cursor: "pointer" }}
                        height="15vh"
                        src={closeIcon}
                        alt={closeIcon}
                      />
                    </Box>
                  );
                })}
            </div>
          </div>
          <button type="submit" className="button-post">
            Preview
          </button>
        </form>
      )}
    </Card>
  );
};
const mapStateToProps = (state) => {
  return {
    upload1Response: state.projectReducer.upload1,
    loadingMediaResponse: state.projectReducer.loadingMedia,
    projectDetailsResponse: state.projectReducer.projectNewDetails,
    removeMediaResponse: state.projectReducer.removeMedia,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadingMedia: (loader) =>
      dispatch({ type: "LOADING_MEDIA", value: loader }),
    upload1: (file) => dispatch({ type: "UPLOAD1", value: file }),
    projectUpdate: (projectFields) =>
      dispatch({ type: "CREATE_UPDATE_IMPACT_PROJECT", value: projectFields }),
    listAllProjects: () => dispatch({ type: "PROJECT_LIST" }),
    editImpactOrUpdate: (updateOrImpactFields) =>
      dispatch({ type: "EDIT_IMPACT_UPDATE", value: updateOrImpactFields }),
    removeUploadContent: () =>
      dispatch({ type: "REMOVE_UPLOAD_CONTENT", value: "" }),
    removeMedia: (media) => dispatch({ type: "REMOVE_MEDIA", value: media }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UpdateOrImpactForm);
