import { Box } from "@mui/material";

const MediaList = ({
  image,
  index,
  video,
  videos,
  modal,
  setModal,
  images,
}) => {
  if (image) {
    return (
      <Box sx={{ position: "relative", textAlign: "initial", color: "white",marginRight:"3%" }}>
        <img
          onClick={() => {
            setModal(!modal);
          }}
          style={{
            background: "more",
            borderRadius: "12px",
            filter: "brightness(50%)",
            cursor: "pointer",
            marginRight: "2%",
            width:"9vw"
          }}
          height="80vh"
          key={index}
          src={image}
          alt={image}
        />
        <Box
          onClick={() => {
            setModal(!modal);
          }}
          sx={{
            display: index === 3 ? "flex" : "none",
            position: "absolute",
            top: "25%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            fontWeight: "bold",
            cursor: "pointer",
          }}
        >
          MORE
        </Box>
      </Box>
    );
  }
  if (videos[index]) {
    return (
      <Box sx={{ position: "relative", textAlign: "initial", color: "white" ,marginRight:"3%"}}>
        <video
          onClick={() => {
            setModal(!modal);
          }}
          style={{
            background: "black",
            opacity: "0.9",
            borderRadius: "12px",
            filter: "brightness(70%)",
            cursor: "pointer",
            position: "relative",
            width:"9vw"
          }}
          height="80vh"
        >
          <source
            onClick={() => {
              setModal(!modal);
            }}
            src={videos[index]}
            alt={videos[index]}
            type="video/webm"
          />
        </video>
        <Box
          onClick={() => {
            setModal(!modal);
          }}
          sx={{
            display: index === 3 ? "flex" : "none",
            position: "absolute",
            top: "25%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            fontWeight: "bold",
            cursor: "pointer",
          }}
        >
          MORE
        </Box>
      </Box>
    );
  }
};
export default MediaList;
