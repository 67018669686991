import Lottie from "react-lottie";
import gifReader from "../../assets/images/loading.json";
import { useLocation, useNavigate } from "react-router";
import { useEffect, useState } from "react";
import edit from "../../assets/images/edit.png";
import imagePlaceholder from "../../assets/images/image-placeholder.jpg";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: gifReader,
  renderer: "svg",
};

const UpdateContainer = (props) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/login");
    }
  }, [navigate, props]);
  return (
    <div>
      {props.updateResponse.results ? (
        props.updateResponse.results.map((data, index) => {
          return (
            <div key={index} className="update-list">
              {data.imageUpdate ? (
                <img
                  width="30%"
                  height="50%"
                  src={data.imageUpdate[0] || imagePlaceholder}
                  alt={data.imageUpdate[0] || imagePlaceholder}
                />
              ) : (
                <video
                  controls
                  src={data.videoUpdate[0]|| imagePlaceholder}
                  alt={data.videoUpdate[0] || imagePlaceholder}
                />
              )}
              <div style={{ display: "block", margin: "0% 0% 0% 5%",fontSize:"1.35vw" }}>
                <div style={{ margin: "0% 0% 3% 0%" }}>
                  <strong>{data.title}</strong>
                </div>
                <div style={{fontSize:"1.1vw"}}>{data.lastUpdate}</div>
              </div>
              <img
                style={{ cursor: "pointer",marginLeft:"70%",position:"absolute" }}
                height="22vh"
                width="22vw"
                src={edit}
                alt={edit}
                onClick={() => {
                  navigate("/projects/update", {
                    state: {
                      projectDetails: props.projectDetails,
                      updateFields: data,
                    },
                  });
                }}
              />
            </div>
          );
        })
      ) : (
        <Lottie options={defaultOptions} height={500} width={500} />
      )}
    </div>
  );
};
export default UpdateContainer;
