import projects from "../assets/images/projects.png";
import donors from "../assets/images/donors.png";
import ngo from "../assets/images/ngo.png";
import contributions from "../assets/images/contributions.png";
export const dashboardTabs = [
  "Overall",
  "On Progress",
  "Closing Soon",
  "Finished",
  "Rejected",
];
export const dashboardTabsNgo = ["NGO", "Donors"];
export const dashboardProjectHeading = [
  "Need Title",
  "Created By",
  "Amount Contributed",
  "Pending Amount",
  "Total Amount",
  "Status",
  "Days Left",
  "Action",
];
export const dashboardNjoHeading = [
  "Name",
  "Phone Number",
  "Email",
  "Verified On",
  "Status",
  "Action",
];
export const pledgedListHeading = [];
export const dashboardDonorHeading = [
  "Name",
  "Phone Number",
  "Email",
  "User ID",
  "Basic Details Completed",
  "Action",
];
export const donationHeading = [
  "Name",
  "Need Title",
  "Amount donated",
  "Date",
  "Status",
];
export const donorDonationHeading = [
  "Project Name",
  "Amount donated",
  "Date",
  "Action",
];
export const allDonationHeading = [
  "Name",
  "Name of N.G.O",
  "Amount donated",
  "Date",
  "Need title",
  "Payment method",
  "Status",
  "Action",
];
// export const BASE_URL = "http://localhost:8080/admin";
// export const BASE_URL = "https://admin-api.ekkajosh.com/admin";
export const BASE_URL = "https://ekj-admin-testing.herokuapp.com/admin";

export const dashboardHomeData = [
  ["Total N.G.O.s", ngo, "#F9FFF8", "totalNgo"],
  ["Total Projects", projects, "#FFFEF8", "totalProjects"],
  ["Total Donors", donors, "#F8F9FF", "totalDonors"],
  ["Total Contributions", contributions, "#FFF8F8", "totalContributions"],
];
export const projectDetailsTabs = ["Story", "Update", "Impact"];
export const expImage = "image";
export const expVideo = "video";
export const expAudio = "audio";
export const expFile = "application";
export const rejectBlogMessage = "Confirm Reject and\nenter your feedback";
export const approveBlogMessage = "Please Confirm\nApprove";
export const deleteProjectMessage = "Are you sure you\nwant to delete";
export const blockUserMessage = "Are you sure you\nwant to block";
export const CreateProjectMessage = "Please Confirm\nPost";
export const EditProjectMessage = "Please Confirm\nEdit";
export const UpdateProjectMessage = "Please Confirm\nUpdate";
export const ImpactProjectMessage = "Please Confirm\nImpact";
export const colorPicker = (status) => {
  if (status === "blocked" || status === "No") {
    return "linear-gradient(89.8deg, #DC4734 -4.18%, #EE503C 99.75%)";
  } else if (status === "verified" || status === "Yes") {
    return "linear-gradient(89.63deg, #64A91F 1.41%, #79C82A 97.96%)";
  } else if (status === "pending") {
    return "linear-gradient(270deg, #FAC11C 0%, #DDA400 100%)";
  }
};
export const colorChange = (status) => {
  console.log(status)
  if (status === "Completed" || status === "successfull") {
    return [
      "linear-gradient(89.63deg, #64A91F 1.41%, #79C82A 97.96%)",
      "/completed.png",
    ];
  } else if (
    status === "In progress" ||
    status === "pending" ||
    status === undefined ||
    status === "created"
  ) {
    return [
      "linear-gradient(270deg, #FAC11C 0%, #DDA400 100%)",
      "/inprogress.png",
    ];
  } else if (status === "Closing") {
    return ["#679FF4", "/closing.png"];
  } else if (status === "rejected" || status === "Refund" || status === "refunded") {
    return [
      "linear-gradient(89.8deg, #DC4734 -4.18%, #EE503C 99.75%)",
      "/rejected.png",
    ];
  }
};

export const donorDialogFields = [
  ["Name", "name"],
  ["Email Address", "emailAddress"],
  ["Gender", "gender"],
  ["D.O.B", "age"],
  ["Phone", "contactNumber"],
  ["Country", "country"],
  ["Location", "location"],
  ["Interested Courses", "interestedCourses"],
];
export const ngoPopupTabs = [
  "Details",
  "Supporting Documents",
  "Bank Account",
  "Transaction history",
  "Comments",
];
export const donorPopupTabs = ["Basic Details", "Transaction History"];
export const NgoDetailsHeading = [
  ["Phone Number", "contactNumber"],
  ["Email id", "emailAddress"],
  ["Gender", "gender"],
  ["Country", "country"],
  ["Contact Number", "contactNumber"],
  ["Address", "address"],
  ["City", "city"],
  ["PinCode", "pinCode"],
  ["State", "state"],
  ["SDG Goal", "goals"],
  ["Founder's Name", "foundersName"],
  ["Founders Contact No:", "founderContactNumber"],
  ["Date Of Incorporation", "dateOfIncorporation"],
  ["Registration Number", "registrationNumber"],
  ["Pan Card No", "pancard"],
  ["F.C.R.A Registration Number", "fcraRegNumber"],
  ["G.S.T Number", "gstNumber"],
  ["Focused Causes", "focusedCourses"],
  ["Accounts Head Name", "accountsHeadName"],
  ["Accounts Head Contact No:", "accountsHeadContact"],
  ["Communications Head Name", "communucationHeadName"],
  ["Communications Head Contact No:", "communucationHeadContact"],
];
export const NgoBankAccountFields = [
  ["Name of Bank", "bankName"],
  ["Name Of Account holder", "nameOfAccountHolder"],
  ["Account Number", "accountNumber"],
  ["I.F.S.C Code", "ifscCode"],
  ["Branch", "branch"],
  ["G-Pay number", "gpayNumber"],
  ["Paytm Number", "paytmNumber"],
];
export const NgoEditFields = ["Details", "Bank Account"];
export const DonorEditFields = [
  ["Name", "name"],
  ["Email Address", "emailAddress"],
  ["Gender", "gender"],
  ["Age", "age"],
  ["Phone", "phone"],
  ["Country", "country"],
  ["Location", "location"],
  ["Interested Courses", "interestedCourses"],
];
export const GenderTabs = ["Male", "Female", "Other"];
export const approveRefundMessage = "Please Confirm\nRefund";
export const approveSettlementMessage = "Please Confirm\nSettleMent";
export const goalData = [
  "No Poverty",
  "Zero Hunger",
  "Good Health and Well-being",
  "Quality Education",
  "Gender Equality",
  "Clean Water and Sanitation",
  "Affordable and Clean Energy",
  "Decent Work and Economic Growth",
  "Industry, Innovation and Infrastructure",
  "Reduced Inequality",
  "Sustainable Cities and Communities",
  "Responsible Consumption and Production",
  "Climate Action",
  "Life Below Water",
  "Life on Land",
  "Peace and Justice Strong Institutions",
  "Partnerships to achieve the Goal",
];