import React from "react";
import { PieChart } from "react-minimal-pie-chart";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import {
  Card,
  Divider,
  List,
  ListItemIcon,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import rightArrow from "../../assets/images/arrowright.png";

const PieChartView = (props) => {
  const labelValue = props.authResponse.allBlogStatus;
  console.log("total", labelValue);
  const navigate = useNavigate();
  return (
    <Card
      sx={{
        display: "inline-flex",
        borderRadius: "12px",
        border: "1px solid #DCDCEB",
        padding: "6% 5% 6% 5%",
        margin: "1% 1% 1% 30%",
      }}
    >
      <PieChart
        style={{ height: "28vh", width: "15vw" }}
        animation
        animationDuration={500}
        animationEasing="ease-out"
        center={[50, 50]}
        data={[
          {
            title: labelValue.closing,
            value: labelValue.closing,
            color: "#5589D8",
          },
          {
            title: labelValue.completed,
            value: labelValue.completed,
            color: "#83E323",
          },
          {
            title: labelValue.inProgress,
            value: labelValue.inProgress,
            color: "#FAC11C",
          },
          {
            title: labelValue.rejected,
            value: labelValue.rejected,
            color: "#F24E3A",
          },
        ]}
        labelPosition={50}
        lengthAngle={360}
        lineWidth={42}
        paddingAngle={0}
        totalValue={labelValue.total}
        radius={40}
        startAngle={0}
        viewBoxSize={[100, 100]}
        rounded
      />
      <Box
        sx={{
          position: "absolute",
          top: "49%",
          left: "58%",
          fontSize: "2.5vw",
          fontWeight:"bold",
          color:"#7E7E7E"
        }}
      >
        {labelValue.total}
      </Box>
      <List component="nav" sx={{ width: "15vw", bgcolor: "#FFFFFF" }}>
        <ListItemButton
          onClick={() => {
            props.dashboardAction("Total Projects");
            props.projectTabAction("Closing Soon");
            props.selectedTab("Closing");
            navigate("/dashboard/details", {
              state: {
                tabValue: "Closing Soon",
                header: "Total Projects",
                selectedValue: "Closing",
              },
            });
          }}
        >
          <Box
            sx={{
              background: "#5589D8",
              p: "3%",
              mr: "5%",
              borderRadius: "100%",
              "&:hover": {
                background: "#FEF6DD",
              },
            }}
          />
          <ListItemText primary="Closing Soon" disableTypography />
          <ListItemIcon>
            <img src={rightArrow} alt={rightArrow} />
          </ListItemIcon>
        </ListItemButton>
        <Divider />

        <ListItemButton
          onClick={() => {
            props.dashboardAction("Total Projects");
            props.projectTabAction("On Progress");
            props.selectedTab("In progress");
            navigate("/dashboard/details", {
              state: {
                tabValue: "On Progress",
                header: "Total Projects",
                selectedValue: "In progress",
              },
            });
          }}
        >
          <Box
            sx={{
              background: "#FAC11C",
              p: "3%",
              mr: "5%",
              borderRadius: "100%",
            }}
          />
          <ListItemText disableTypography primary="On Progress" />
          <ListItemIcon>
            <img src={rightArrow} alt={rightArrow} />
          </ListItemIcon>
        </ListItemButton>
        <Divider />
        <ListItemButton
          onClick={() => {
            props.dashboardAction("Total Projects");
            props.projectTabAction("Finished");
            props.selectedTab("Completed");
            navigate("/dashboard/details", {
              state: {
                tabValue: "Finished",
                header: "Total Projects",
                selectedValue: "Completed",
              },
            });
          }}
        >
          <Box
            sx={{
              background: "#83E323",
              p: "3%",
              mr: "5%",
              borderRadius: "100%",
            }}
          />
          <ListItemText disableTypography primary="Finishing" />
          <ListItemIcon>
            <img src={rightArrow} alt={rightArrow} />
          </ListItemIcon>
        </ListItemButton>
        <Divider />
        <ListItemButton
          onClick={() => {
            props.dashboardAction("Total Projects");
            props.projectTabAction("Rejected");
            props.selectedTab("Rejected");
            navigate("/dashboard/details", {
              state: {
                tabValue: "Rejected",
                header: "Total Projects",
                selectedValue: "Rejected",
              },
            });
          }}
        >
          <Box
            sx={{
              background: "#F24E3A",
              p: "3%",
              mr: "5%",
              borderRadius: "100%",
            }}
          />
          <ListItemText disableTypography primary="Rejected" />
          <ListItemIcon>
            <img src={rightArrow} alt={rightArrow} />
          </ListItemIcon>
        </ListItemButton>
      </List>
    </Card>
  );
};
export default PieChartView;
