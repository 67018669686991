import Dialog from '@mui/material/Dialog';
import React from "react";
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Input from '@mui/material/Input';
import { ConfirmationDialogStyles } from './confirmationDialogStyle';


const ConfirmationDialog = (props) => {
    const classes = ConfirmationDialogStyles();

    const handleClose = () => {
        props.setModal(false);
    };
    return (
        <Dialog open={props.modal} onClose={handleClose}>
            <DialogContent className={classes.dialogContent}>
                <img height="50vh" width="50vw" src={props.image} alt={props.image} />
                <DialogContentText className={classes.textHeader} >
                    {props.title}
                </DialogContentText>
                {props.status === "rejected" ?
                    <Input className={classes.text}
                        inputProps={{ style: { fontSize: "1vw" } }}
                        variant="outlined"
                        fullWidth
                        value={props.feedback}
                        onChange={(e) => { props.setFeedback(e.target.value) }}
                        multiline
                        placeholder='Enter a feedback'
                        rows={7}
                        style={{ marginTop: 20 }}
                        disableUnderline={true}
                    /> : ""}
                <DialogContentText >
                    <Button className={classes.buttonSubmit} fullWidth onClick={props.submit}>Submit</Button>
                </DialogContentText>
                <DialogContentText >
                    <Button className={classes.buttonCancel} fullWidth onClick={handleClose}>Cancel</Button>
                </DialogContentText >
            </DialogContent>
        </Dialog>
    )
}
export default ConfirmationDialog;