import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  "&.MuiPaper-root":{
    overflowY:"hidden",
  },
  newButton: {
    background: "#F9C11D",
    borderRadius: "7px",
    minWidth: "max-content",
    padding: "1% 3% 1% 3%",
    "&:hover": {
      backgroundColor: "#F9C11D",
      opacity: 0.9,
    },
  },
  searchContainer: {
    marginLeft: "58%",
    padding: "2% 0% 2% 2%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  projectRow: {
    "&:last-child td, &:last-child th": { border: 0 },
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#FEF6DD",
    },
  },
  menu:{
    "& .MuiMenu-paper":{
      boxShadow:"none",
      border:"1px solid #EBEBEB",
    },
  }
});
