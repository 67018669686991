import {
  TableRow,
  ListItemText,
  TableCell,
  MenuItem,
  ListItemIcon,
  Menu,
  Box,
  ListItemAvatar
} from "@mui/material";
import React from "react";
import deleteMenu from "../../assets/images/delete-menu.png";
import block from "../../assets/images/block.png";
import view from "../../assets/images/view.png";
import more from "../../assets/images/more.png";
import { useState } from "react";
import { colorPicker } from "../../constants/constants";
import { dashboardDetailsStyle } from "./dashboardDetailsStyle";
import NgoProfile from "../ngoProfile/ngoProfile";
import approveImage from "../../assets/images/approve-image.png";
import DeleteImage from "../../assets/images/delete-image.png";
import blockImage from "../../assets/images/block-image.png";
import { blockUserMessage } from "../../constants/constants";
import { approveBlogMessage } from "../../constants/constants";
import { deleteProjectMessage } from "../../constants/constants";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import ConfirmationDialog from "../dialogBox/confirmationDialog";

let status = "";
let image = "";
let title = "";
let userData = {};

const NgoContainer = ({
  approveNgo,
  data,
  listAllNgo,
  ngoImage,
  index,
  deleteNgo,
  loadingMedia,
  page,
  addComments,
}) => {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [dialog, setDialog] = useState(false);
  const classes = dashboardDetailsStyle();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleApprove = (action) => {
    if (modal) {
      setModal(!modal);
      approveNgo(userData);
      loadingMedia(true);
      userData = {};
      const timer = setTimeout(() => {
        listAllNgo(page);
        navigate("/dashboard/details", { state: { header: "Total N.G.O.s" } });
      }, 200);
      return () => clearTimeout(timer);
    } else {
      if (action === "approve") {
        image = approveImage;
        title = approveBlogMessage;
      } else {
        image = blockImage;
        title = blockUserMessage;
      }
      userData = data;
      userData.action = action;
      status = "approve";
      setModal(!modal);
    }
  };
  const handleDelete = () => {
    status = "";
    if (modal) {
      deleteNgo(data);
      setModal(!modal);
      let timer = setTimeout(() => {
        listAllNgo();
        if (dialog) setDialog(!dialog);
      }, 200);
      return () => clearTimeout(timer);
    } else {
      image = DeleteImage;
      title = deleteProjectMessage;
      status = "delete";
      setModal(!modal);
    }
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <ConfirmationDialog
        submit={status === "approve" ? handleApprove : handleDelete}
        image={image}
        title={title}
        modal={modal}
        setModal={setModal}
      />
      {dialog ? (
        <NgoProfile
          handleApprove={handleApprove}
          handleDelete={handleDelete}
          data={data}
          dialog={dialog}
          title={title}
          image={image}
          status={status}
          setDialog={setDialog}
          addComments={addComments}
          page={page}
        />
      ) : (
        ""
      )}
      <TableRow
        key={data.name}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
          background: index % 2 === 0 ? "#ffff" : "#FBFBFB",
        }}
      >
        <TableCell sx={{ display: "flex", fontSize: "1vw" }} align="left">
          <img
            style={{ borderRadius: "100%", marginRight: "5%" }}
            width="50vw"
            height="50vh"
            src={data.profilePic || ngoImage}
            alt={data.profilePic || ngoImage}
          />
          <Box sx={{ display: "grid", rowGap: "1px" }}>
            <ListItemAvatar
              disableTypography
              sx={{
                fontWeight: "bold",
                textTransform: "uppercase",
                fontSize: "1vw",
                width: "20vw",
              }}
            >
              {data.name}
            </ListItemAvatar>
            <ListItemText
              disableTypography
              sx={{
                fontWeight: "bold",
                textTransform: "uppercase",
                color: "#717171",
                fontSize: "0.9vw",
              }}
            >
              {data.uniqueId}
            </ListItemText>
          </Box>
        </TableCell>
        <TableCell
          sx={{ textTransform: "uppercase", fontSize: "1vw" }}
          align="left"
        >
          <strong>{data.contactNumber}</strong>
        </TableCell>
        <TableCell
          sx={{ textTransform: "uppercase", fontSize: "1vw" }}
          align="left"
        >
          <strong>{data.emailAddress}</strong>
        </TableCell>
        <TableCell
          sx={{ textTransform: "uppercase", fontSize: "1vw" }}
          align="left"
        >
          <strong>{data.lastModifiedDate}</strong>
        </TableCell>
        <TableCell sx={{ fontSize: "1vw" }} align="left">
          <Box
            sx={{
              background: colorPicker(data.status),
              textTransform: "uppercase",
              color: "#ffffff",
              textAlign: "center",
              padding: "3% 0% 3% 0%",
              alignItems: "center",
              borderRadius: "30px",
              display: "flex",
              width: "8vw",
            }}
          >
            <img
              style={{
                borderRadius: "100%",
                marginRight: "5%",
                marginLeft: "5%",
              }}
              src={"/" + data.status + ".png"}
              alt={"/" + data.status + ".png"}
            />
            {data.status}
          </Box>
        </TableCell>
        <TableCell align="left">
          <img
            style={{ cursor: "pointer" }}
            onClick={handleClick}
            src={more}
            alt={more}
          />
          <Menu
            className={classes["& .MuiList-root"]}
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            autoFocus={false}
          >
            <MenuItem
              onClick={() => {
                setDialog(!dialog);
                handleClose();
              }}
              sx={{ fontSize: "1vw" }}
            >
              <ListItemIcon>
                <img src={view} alt={view} />
              </ListItemIcon>
              View
            </MenuItem>
            {data.status === "blocked" ? (
              <MenuItem
                onClick={() => {
                  handleApprove("approve");
                  handleClose();
                }}
                sx={{ fontSize: "1vw" }}
              >
                <ListItemIcon>
                  <img src={block} alt={block} />
                </ListItemIcon>
                Approve
              </MenuItem>
            ) : (
              <MenuItem
                onClick={() => {
                  handleApprove("block");
                  handleClose();
                }}
                sx={{ fontSize: "1vw" }}
              >
                <ListItemIcon>
                  <img src={block} alt={block} />
                </ListItemIcon>
                Block
              </MenuItem>
            )}

            <MenuItem
              onClick={() => {
                handleDelete();
                handleClose();
              }}
              sx={{ fontSize: "1vw" }}
            >
              <ListItemIcon>
                <img src={deleteMenu} alt={deleteMenu} />
              </ListItemIcon>
              Delete
            </MenuItem>
          </Menu>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    deleteResponse: state.usersReducer.delete,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    approveNgo: (userData) =>
      dispatch({ type: "APPROVE_NGO", value: userData }),
    listAllNgo: () => dispatch({ type: "NGO_LIST" }),
    editNgo: (userData) => dispatch({ type: "EDIT_NGO", value: userData }),
    deleteNgo: (userData) => dispatch({ type: "DELETE_NGO", value: userData }),
    loadingMedia: (loader) =>
      dispatch({ type: "LOADING_MEDIA", value: loader }),
    addComments: (data) => dispatch({ type: "NGO_COMMENTS", value: data }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(NgoContainer);
