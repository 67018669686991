import { makeStyles } from '@mui/styles';

export const ConfirmationDialogStyles = makeStyles({
    textHeader: {
        fontSize: "1.1vw",
        fontWeight: "bold",
        whiteSpace: "pre-line"
    },
    text: {
        background: '#FFFFFF',
        border: "1px solid #CACACA",
        borderRadius: 6,
        color: 'black',
        height: "100%",
        fontSize: "1vw",
        textTransform: "initial",
        padding: "5%",
    },
    buttonSubmit: {
        background: '#FAC11C',
        borderRadius: 12,
        color: 'black',
        height: "100%",
        fontSize: "1vw",
        padding: "3%",
        margin: "15% 0% 5% 0%",
        textTransform: "initial",
        "&:hover": {
            backgroundColor: "#FAC11C"
        }

    },
    buttonCancel: {
        background: '#FFFFFF',
        borderRadius: 12,
        fontSize: "1vw",
        color: 'black',
        border: "1px solid #DCDCEB",
        height: "100%",
        padding: "3%",
        margin: "3% 0% 10% 0%",
        textTransform: "initial",
        "&:hover": {
            backgroundColor: "#FFFFFF",
            border: "1px solid #DCDCEB",
        }
    },
    dialogContent: {
        borderRadius: 30,
        textAlign: "center",
        width: "18vw"
    }
})