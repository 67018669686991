import Navbar from "../../../components/navbar/navbar";
import Header from "../../../components/header/header";
import React from "react";
import UpdateOrImpactForm from "../../../components/projectForm/updateOrImpactForm";
import { Card } from "@mui/material";

class CreateImpactProject extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      description: "",
      imageMedia: "",
    };
  }
  render() {
    return (
      <div>
        <Navbar active="Projects" />
        <Header heading="HOME/PROJECTS" />
        <Card
          sx={{
            margin: "0% 0% 0% 11.7%",
            padding: "0% 3% 9% 5%",
            border: "1px solid #E5E5E5",
            borderRadius: "15px",
          }}
        >
          <UpdateOrImpactForm
            heading="Post an Impact"
            type="impact"
            {...this.state}
          />
        </Card>
      </div>
    );
  }
}
export default CreateImpactProject;
