import { Typography, Box, Grid, Divider } from "@mui/material";
import { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie";
import gifReader from "../../assets/images/loading.json";
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: gifReader,
  renderer: "svg",
};
function createMarkup(description) {
  return { __html: description };
}
const NewsContainer = (props) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (
      props.projectListResponse.type === "project" ||
      !props.projectListResponse.results
    ) {
      props.loadingMedia(true);
      props.listAllProjects(1, "news");
    }
  }, [props]);
  return props.loadingMediaResponse ? (
    <Lottie options={defaultOptions} height={500} width={500} />
  ) : props.projectListResponse.results && props.projectListResponse.results.length > 0 ? (
    <Box>
      <Typography
        sx={{
          fontSize: "1.5em",
          fontWeight: "bold",
          margin: '3% 0 0 3%',
          // marginBottom: "2%",
          // marginLeft: "3%",
        }}
      >
        Hot Topics
      </Typography>
      <Grid container sx={{ padding: "3%", cursor: "pointer" }}>
        <Grid item lg={6} md={12}>
          <img
            height={300}
            width={500}
            style={{ borderRadius: '8px', marginRight: '2%', marginBottom: '2%' }}
            src={props.projectListResponse.results[0].imageMedia}
            alt={props.projectListResponse.results[0].imageMedia}
          />
        </Grid>
        <Grid item lg={6} md={12}>
          <Box sx={{ margin: "0% 3% 0% 0%", flexWrap: 'wrap' }}>
            <Typography sx={{ textAlign: "justify",fontSize:"1vw" }}
              dangerouslySetInnerHTML={createMarkup(props.projectListResponse.results[0].description)}
            ></Typography>
            <Typography
              sx={{ fontSize: "0.9vw", fontWeight: "bold", color: "grey", marginTop: "2%" }}
            >
              {props.projectListResponse.results[0].createdTime}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Divider />
      <Box>
        {/* <Typography
          sx={{
            fontSize: "1.5em",
            fontWeight: "bold",
            marginBottom: "2%",
            marginLeft: "3%",
          }}
        >
          Hot Topics
        </Typography> */}
        <Box display="flex" sx={{ margin: "3% 3% 3% 3%", flexWrap: 'wrap' }}>
          {props.projectListResponse.results.map((news, index) => {
            return (
              <Box>
                <img
                  style={{
                    borderRadius: "8px",
                    margin: "30px 30px 0 0",
                    cursor: "pointer",
                  }}
                  width={250}
                  height={180}
                  src={news.imageMedia[0]}
                  alt={news.imageMedia[0]}
                  onClick={() => {
                    navigate("/newsArticles/" + news.b_id, {
                      state: { projectDetails: news },
                    });
                  }}
                />
                <Typography sx={{ fontSize: "0.85em" }}>
                  {news.createdTime}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.95em",
                    width: "250px",
                    fontWeight: "bold",
                    textAlign: "justify",
                  }}
                >
                  {news.title}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  ) : (
    ""
  );
};
const mapStateToProps = (state) => {
  return {
    projectListResponse: state.projectReducer.results,
    loadingMediaResponse: state.projectReducer.loadingMedia,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadingMedia: (loader) =>
      dispatch({ type: "LOADING_MEDIA", value: loader }),
    listAllProjects: (page, type) =>
      dispatch({ type: "PROJECT_LIST", value: { type: type, page: page } }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(NewsContainer);
