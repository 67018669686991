import React from "react"
const Tabs = (props) => {
    

    return(
        <div className="tab-container">
        {props.Tabs.map((tab, index) => {
          return (
            <button
              onClick={() => {
                props.tabChange(tab);
              }}
              className={
                props.tabValue === tab ? "dashboard-tabs-active" : "dashboard-tabs"
              }
              key={index}
            >
              {tab}
            </button>
          );
        })}
      </div>
    )
}
export default Tabs;