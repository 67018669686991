import {combineReducers,createStore,applyMiddleware} from "redux";
import createSagaMiddleware from "redux-saga";
import loginReducer from "./reducer/loginReducer";
import projectReducer from "./reducer/projectReducer";
import usersReducer from "./reducer/usersReducer";
import transactionsReducer from "./reducer/transactions";
import rootSaga from "./sagas";

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];
const appReducer = combineReducers({
    loginReducer:loginReducer,
    projectReducer:projectReducer,
    usersReducer:usersReducer,
    transactionsReducer:transactionsReducer
})
const rootReducer = (state,action) => {
    if(action.type === "LOGOUT") {
        state = undefined
    }
    return appReducer(state,action)
}
const store = createStore(rootReducer, {}, applyMiddleware(...middleware));
sagaMiddleware.run(rootSaga);

export default store;