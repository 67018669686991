import React, { useState, useEffect } from "react";
import "./projectDetailsContainer.scss";
import ProgressBar from "@ramonak/react-progress-bar";
import { useNavigate, useLocation } from "react-router-dom";
import {
  projectDetailsTabs,
  rejectBlogMessage,
  approveBlogMessage,
  deleteProjectMessage,
} from "../../constants/constants";
import backButton from "../../assets/images/back.svg";
import { connect } from "react-redux";
import UpdateContainer from "./updateContainer";
import ImpactContainer from "./impactContainer";
import approve from "../../assets/images/approve.png";
import reject from "../../assets/images/reject.png";
import edit from "../../assets/images/edit.png";
import deleteIcon from "../../assets/images/delete.png";
// import roundaddbtn from "../../assets/images/roundadd.png";
import { Document, Page, pdfjs } from "react-pdf";
import DialogBox from "../dialogBox/confirmationDialog";
import rejectImage from "../../assets/images/reject-image.png";
import approveImage from "../../assets/images/approve-image.png";
import DeleteImage from "../../assets/images/delete-image.png";
import { Card, Box, Button, Typography } from "@mui/material";
import { useStyles } from "./projectDetailsContainerStyle";
import MediaPlayer from "../mediaPlayer/mediaPlayer";
import PdfViewer from "../pdfViewer/pdfViewer";
import imagePlaceholder from "../../assets/images/image-placeholder.jpg";
import DocViewer, { DocViewerRenderers  } from "react-doc-viewer";
let status = "";
let title = "";
let image = "";
let fileSelected = "";
function createMarkup(description) {
  return { __html: description };
}

const ProjectDetailsContainer = (props) => {
  const classes = useStyles();
  const [modal, setModal] = useState(false);
  const [pdfmodal, setPdfModal] = useState(false);
  const [feedback, setFeedback] = useState("");
  let docs = [
     {uri:"", fileType:""},
   
  ];
  document.addEventListener("contextmenu", (event) => {
    event.preventDefault();
  });
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const location = useLocation();
  const { projectDetails } = location.state;
  const projectStatusChange = (action) => {
    if (modal) {
      setModal(!modal);
      projectDetails.status = status;
      projectDetails.feedback = feedback;
      props.projectStatusChange(projectDetails);
      let timer = setTimeout(() => {
        props.listAllProjects();
      }, 200);
      return () => clearTimeout(timer);
    } else {
      if (action === "approved") {
        image = approveImage;
        title = approveBlogMessage;
      } else {
        image = rejectImage;
        title = rejectBlogMessage;
      }
      status = action;
      setModal(!modal);
    }
  };

  const handleUpdateOrImpact = (tab) => {
    if (tab !== "Story") {
      tab === "Update"
        ? props.getProjectUpdateOrImpact(tab, projectDetails.updates)
        : props.getProjectUpdateOrImpact(tab, projectDetails.impacts);
    }
    setTabValue(tab);
  };

  const handleDelete = () => {
    status = "";
    if (modal) {
      setModal(!modal);
      props.deleteProject(projectDetails.b_id);
      let timer = setTimeout(() => {
        props.listAllProjects();
        navigate("/projects");
      }, 200);
      return () => clearTimeout(timer);
    } else {
      image = DeleteImage;
      setModal(!modal);
      title = deleteProjectMessage;
    }
  };
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/login");
    }
    if (props.projectDetailsResponse.results || props.upload1Response.results) {
      props.removeDetails();
    }
  }, [navigate, props]);
  const [tabValue, setTabValue] = useState("Story");

  return (
    <Card
      sx={{
        borderRadius: "21px",
        border: "1px solid #EBEBEB",
        margin: "3% 2% 1% 2%",
        paddingBottom: "5%",
      }}
    >

      
        
      {console.log("filSelected", docs)}
      {pdfmodal ? (
        <PdfViewer
          pdfmodal={pdfmodal}
          setPdfModal={setPdfModal}
          otherMedia={projectDetails.otherMedia}
          fileSelected={fileSelected}
        />
       
      ) : (
        ""
      )}
      {modal ? (
        <DialogBox
          status={status}
          feedback={feedback}
          setFeedback={setFeedback}
          submit={status !== "" ? projectStatusChange : handleDelete}
          handleDelete={handleDelete}
          image={image}
          title={title}
          modal={modal}
          setModal={setModal}
        />
      ) : (
        ""
      )}
      <Box
        style={{
          display: "flex",
          margin: "1% 0% 1% 0%",
          padding: "0% 0% 0% 3%",
          alignItems: "center",
          borderBottom: "1px solid #EDEDED",
        }}
      >
        <img
          onClick={() => {
            navigate("/projects");
          }}
          style={{ cursor: "pointer", width: "1.25vw" }}
          src={backButton}
          alt={backButton}
        />
        <Box
          style={{
            margin: "2% 3% 2% 2%",
            fontSize: "1.25vw",
            display: "flex",
            textTransform: "uppercase",
            fontWeight: "bold",
          }}
        >
          <Typography sx={{ width: "max-content" }}>
            {projectDetails.title}
          </Typography>
        </Box>
        <Box
          className={
            projectDetails.status === "approved"
              ? classes.approveStatus
              : classes.rejectStatus
          }
        >
          {projectDetails.status}
        </Box>
        {projectDetails.status === "approved" ? (
          <Button
            className={classes.rejectButton}
            onClick={() => {
              projectStatusChange("rejected");
            }}
          >
            Reject
          </Button>
        ) : (
          <Button
            className={classes.approveButton}
            onClick={() => {
              projectStatusChange("approved");
            }}
          >
            <img src={approve} alt={approve} />
            Approve
          </Button>
        )}
      </Box>
      <Box
        sx={{ display: "flex", alignItems: "center", margin: "1% 1% 1% 3%" }}
      >
        <Typography
          sx={{ display: "flex", alignItems: "center"}}
        >
          <Typography variant="subtitle1" sx={{ width: "15vw"}}>
            <strong>Interest Course</strong>
          </Typography>
          <Typography className={classes.course}>
            {projectDetails.cause}
            {/* <img height="12vh" src={closeIcon} alt={closeIcon}/> */}
          </Typography>
          {/* <img
            onClick={() => {
              navigate("/projects/create", {
                state: { editprojectDetails: location.state.projectDetails },
              });
            }}
            height="22vh"
            width="22vw"
            style={{ marginLeft: "1vw" }}
            src={roundaddbtn}
            alt={roundaddbtn}
          /> */}
        </Typography>
        <Box sx={{ marginLeft: "67%", display: "flex",position:"absolute" }}>
          <img
            style={{ cursor: "pointer" }}
            height="22vh"
            width="22vw"
            src={edit}
            alt={edit}
            onClick={() => {
              navigate("/projects/create", {
                state: { editprojectDetails: location.state.projectDetails },
              });
            }}
          />
          &nbsp;&nbsp;&nbsp;&nbsp;
          <img
            style={{ cursor: "pointer" }}
            height="22vh"
            width="22vw"
            onClick={() => {
              handleDelete();
            }}
            src={deleteIcon}
            alt={deleteIcon}
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          margin: "3% 0% 0% 3%",
        }}
      >
        {projectDetails &&
        projectDetails.videoMedia &&
        projectDetails.videoMedia.length > 0 ? (
          <video
            style={{
              borderRadius: "25px",
              width: "50vw",
              height: "40vh",
            }}
            controls
          >
            <source
              src={
                projectDetails.videoMedia[
                  projectDetails.videoMedia.length - 1
                ] || imagePlaceholder
              }
              alt={
                projectDetails.videoMedia[
                  projectDetails.videoMedia.length - 1
                ] || imagePlaceholder
              }
              type="video/webm"
            />
          </video>
        ) : (
          <img
            style={{
              borderRadius: "25px",
              width: "50vw",
              height: "40vh",
              cursor: "pointer",
            }}
            src={
              projectDetails.imageMedia[projectDetails.imageMedia.length - 1] ||
              imagePlaceholder
            }
            alt={
              projectDetails.imageMedia[projectDetails.imageMedia.length - 1] ||
              imagePlaceholder
            }
          />
        )}

        <Box sx={{ marginLeft: "5%" }}>
          <Typography>
            <strong>0</strong>
          </Typography>
          RAISED OF ₹&nbsp;{projectDetails.amountNeeded}
          <ProgressBar
            margin="3% 1% 3% 0%"
            isLabelVisible={false}
            baseBgColor="#F3F3F3"
            bgColor="#FAC11C"
            width="20vw"
            height="2vh"
            completed={320000}
            maxCompleted={parseInt(projectDetails.amountNeeded)}
          />
          <Typography>
            <b>0</b> People Donated&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <b>{projectDetails > 0 ? projectDetails.daysLeft : 0}</b>&nbsp;Days
            left
          </Typography>
          <MediaPlayer
            images={projectDetails.imageMedia}
            videos={projectDetails.videoMedia}
          />
        </Box>
      </Box>
      {projectDetails.audioMedia.length > 0 ? (
        <Box className={classes.audio}>
          <audio
            style={{ height: "8vh", width: "20vw" }}
            controls
            src={
              projectDetails.audioMedia[projectDetails.audioMedia.length - 1]
            }
          >
            Your browser does not support the
            <code>audio</code> element.
          </audio>
          <audio
            style={{ height: "8vh", width: "20vw" }}
            controls
            src={
              projectDetails.audioMedia[projectDetails.audioMedia.length - 1]
            }
          >
            Your browser does not support the
            <code>audio</code> element.
          </audio>
        </Box>
      ) : (
        ""
      )}
      <div className="details">
        <div className="story-update-impact">
          {projectDetailsTabs.map((tab, index) => {
            return (
              <div
                onClick={() => {
                  handleUpdateOrImpact(tab);
                }}
                key={index}
                className={
                  tabValue === tab ? "project-tabs-active" : "project-tabs"
                }
              >
                {tab}
              </div>
            );
          })}
          {tabValue !== "Story" ? (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                marginLeft: "55%",
              }}
            >
              <Button
                className={classes.newButton}
                onClick={() => {
                  navigate("/projects/" + tabValue, {
                    state: { projectDetails: projectDetails },
                  });
                }}
              >
                +
              </Button>
              <Box sx={{ marginLeft: "5%" }}>Add new {tabValue}</Box>
            </Box>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="details">
        {tabValue !== "Story" ? (
          tabValue === "Update" ? (
            <UpdateContainer
              projectDetails={projectDetails}
              updateResponse={props.updateResponse}
            />
          ) : (
            <ImpactContainer
              projectDetails={projectDetails}
              impactResponse={props.impactResponse}
            />
          )
        ) : (
          <div>
            <div
              dangerouslySetInnerHTML={createMarkup(projectDetails.description)}
            />
            <div className="fund-header">Fund Details :</div>
            <div className="fund-details">{projectDetails.donationDetails}</div>
            <div className="fund-header">Supporting Documents :
          
            </div>
            <div style={{ display: "flex" }}>
              {projectDetails.otherMedia.map((file, index) => {
              { docs=[{uri:file, fileType:file.substr(file.indexOf(".",file.length-5 )+1, file.length)}]}
              return (
                  <React.Fragment key={index}>
                        <DocViewer style={{marginLeft:"5vh", width:"20vw", height:"50vh"}} pluginRenderers={ DocViewerRenderers} documents={docs} />
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </Card>
  );
};
const mapStateToProps = (state) => {
  return {
    upload1Response: state.projectReducer.upload1,
    upload2Response: state.projectReducer.upload2,
    updateResponse: state.projectReducer.update,
    impactResponse: state.projectReducer.impact,
    projectDetailsResponse: state.projectReducer.projectNewDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    deleteProject: (id) => dispatch({ type: "DELETE_PROJECT", value: id }),
    projectStatusChange: (data) =>
      dispatch({ type: "CHANGE_PROJECT_STATUS", value: data }),
    listAllProjects: () => dispatch({ type: "PROJECT_LIST" }),
    removeDetails: () =>
      dispatch({ type: "REMOVE_UPDATE_IMPACT_PROJECT_ASYNC" }),
    getProjectUpdateOrImpact: (tab, data) =>
      dispatch({
        type: "GET_PROJECT_UPDATE_IMPACT",
        value: { data: data, tab: tab },
      }),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectDetailsContainer);
