import React, { useEffect, useState } from "react";
import Header from "../../components/header/header";
import Navbar from "../../components/navbar/navbar";
import Lottie from "react-lottie";
import gifReader from "../../assets/images/loading.json";
import { Card } from "@mui/material";
import Search from "../../components/search/search";
import { connect } from "react-redux";
import { useStyles } from "../../components/projectListContainer/projectListContainerStyle";
import { allDonationHeading } from "../../constants/constants";
import {
  Box,
  TableCell,
  TableRow,
  TableHead,
  Paper,
  Table,
  TableContainer,
  TableBody,
} from "@mui/material";
import DonationContainer from "../../components/donationContainer/donationContainer";
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: gifReader,
  renderer: "svg",
};
const DonationHistory = (props) => {
  const classes = useStyles();
  const [heading, setHeading] = useState(allDonationHeading);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  useEffect(() => {
    props.loadingMedia(true);
    props.listAllTransactions();
  }, []);
  return (
    <React.Fragment>
      <Navbar active="Donation History" />
      <Header heading="HOME/DONATION HISTORY/DONORS" />
      <Card
        sx={{
          margin: "0% 0% 0% 11.7%",
          padding: "3% 2% 9% 4%",
          border: "1px solid #E5E5E5",
          borderRadius: "15px",
        }}
      >
        <React.Fragment>
          {props.loadingMediaResponse ? (
            <Lottie options={defaultOptions} height={500} width={500} />
          ) : (
            <Card
              sx={{
                margin: "0% 0% 0% 0%",
                border: "1px solid #EBEBEB",
                borderRadius: "21px",
                borderTopLeftRadius: "0px",
              }}
            >
              <Box className={classes.searchContainer}>
                <Search page={page} />
              </Box>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow sx={{ background: "#F5F5F5" }}>
                      {heading &&
                        heading.map((header, index) => {
                          return (
                            <TableCell
                              align="left"
                              sx={{ fontSize: "0.9vw" }}
                              key={index}
                            >
                              {header}
                            </TableCell>
                          );
                        })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.transactionResponse.results
                      ? props.transactionResponse.results.map((data, index) => {
                          return <DonationContainer data={data} />;
                        })
                      : ""}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          )}
        </React.Fragment>
      </Card>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    loadingMediaResponse: state.projectReducer.loadingMedia,
    transactionResponse: state.transactionsReducer.allDonations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadingMedia: (loader) =>
      dispatch({ type: "LOADING_MEDIA", value: loader }),
    listAllTransactions: () => dispatch({ type: "LIST_TRANSACTIONS" }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DonationHistory);
