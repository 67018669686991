import {
  Box,
  Button,
  Input,
  Typography,
  MenuItem,
  Select,
  Chip,
} from "@mui/material";
import { useState } from "react";
import { ngoProfileStyles } from "./ngoProfileStyles";
import OutlinedInput from "@mui/material/OutlinedInput";
import { Theme, useTheme } from "@mui/material/styles";

import { connect } from "react-redux";
import React from "react";
import { useNavigate } from "react-router-dom";
import ConfirmationDialog from "../dialogBox/confirmationDialog";
import { EditProjectMessage } from "../../constants/constants";
import approveIcon from "../../assets/images/approve-image.png";
import { countries } from "../../assets/countries";
import { DatePicker } from "../datepicker/datepicker";
import { GenderTabs } from "../../constants/constants";
import { goalData } from "../../constants/constants";
import FormControl from "@mui/material/FormControl";

let image = "";
let title = "";
let status = "";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, goal, theme) {
  return {
    fontWeight:
      goal.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const NgoEdit = ({
  value,
  handleApprove,
  data,
  edit,
  setEdit,
  editNgo,
  listAllNgo,
  page,
}) => {
  const [userData, setUserData] = useState(data);
  const navigate = useNavigate();
  const theme = useTheme();
  const [modal, setModal] = useState(false);
  const inputRef = React.createRef();
  const [goal, setGoals] = useState(
    userData.goals && userData.goals.length != 0 ? userData.goals : []
  );

  const handleChangeSelect = (event) => {
    const {
      target: { value },
    } = event;
    console.log(typeof(value),value)
    userData.goals = typeof value === "string" ? value.split(",") : value;
    setUserData(userData);
    setGoals(
      typeof value === "string" ? value.split(",") : value
    );
    
  };
  const handleSubmit = () => {
    if (modal) {
      console.log("----", userData);
      setEdit(!edit);
      userData.type = "NGO";
      editNgo(userData);
      const timer = setTimeout(() => {
        console.log("page", page);
        listAllNgo(page);
        setModal(!modal);
        navigate("/dashboard/details", { state: { header: "Total N.G.O.s" } });
      }, 200);
      return () => clearTimeout(timer);
    } else {
      setModal(!modal);
      image = approveIcon;
      title = EditProjectMessage;
      status = "approve";
    }
  };
  const handleChange = (event, formFields) => {
    setUserData((prevState) => ({
      ...prevState,
      [formFields]: event.target.value,
    }));
  };

  const classes = ngoProfileStyles();
  return (
    <React.Fragment>
      <ConfirmationDialog
        submit={status === "approve" ? handleSubmit : ""}
        image={image}
        title={title}
        modal={modal}
        setModal={setModal}
      />
      {value === 0 ? (
        <React.Fragment>
          <Typography className={classes.title}>Name*:</Typography>
          <Input
            className={`${classes.text} ${classes.input}`}
            inputProps={{ style: { fontSize: "0.9vw" } }}
            variant="outlined"
            fullWidth
            value={userData.name}
            onChange={(e) => {
              handleChange(e, "name");
            }}
            placeholder="name"
            disableUnderline={true}
          />

          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box
              sx={{
                display: "grid",
                width: "17vw",
                justifyContent: "space-between",
              }}
            >
              <Typography className={classes.title}>Email Address*:</Typography>
              <Input
                disabled
                className={`${classes.text} ${classes.input}`}
                inputProps={{ style: { fontSize: "0.9vw" } }}
                variant="outlined"
                value={userData.emailAddress}
                onChange={(e) => {
                  handleChange(e, "emailAddress");
                }}
                placeholder="email Address"
                disableUnderline={true}
              />
              <Typography className={classes.title}>City*:</Typography>
              <Input
                className={`${classes.text} ${classes.input}`}
                inputProps={{ style: { fontSize: "0.9vw" } }}
                variant="outlined"
                value={userData.city}
                onChange={(e) => {
                  handleChange(e, "city");
                }}
                placeholder="City"
                disableUnderline={true}
              />
              <Typography className={classes.title}>Country*:</Typography>
              <Select
                sx={{ fontSize: "0.9vw" }}
                value={userData.country}
                onChange={(e) => {
                  handleChange(e, "country");
                }}
                size="small"
              >
                <MenuItem value="default">{userData.country}</MenuItem>
                {countries &&
                  countries.map((country, code) => (
                    <MenuItem value={country.name}>
                      {country.name}&nbsp;&nbsp;&nbsp;({country.code})
                    </MenuItem>
                  ))}
              </Select>
              <Typography className={classes.title}>
                Contact Number*:
              </Typography>
              <Input
                error
                className={`${classes.text} ${classes.input}`}
                inputProps={{ style: { fontSize: "0.9vw" } }}
                variant="outlined"
                value={userData.contactNumber}
                type="number"
                onChange={(e) => {
                  handleChange(e, "contactNumber");
                }}
                placeholder="Contact Number"
                disableUnderline={true}
              />
              <Typography className={classes.title}>SDG Goal*</Typography>
              <FormControl sx={{ m: 1, width: 300 }}>
                <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  value={goal}
                  onChange={handleChangeSelect}
                  input={
                    <OutlinedInput id="select-multiple-chip" label="Chip" />
                  }
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {goalData.map((name) => (
                    <MenuItem
                      key={name}
                      value={name}
                      style={getStyles(name, goal, theme)}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Typography className={classes.title}>Founders Name*:</Typography>
              <Input
                className={`${classes.text} ${classes.input}`}
                inputProps={{ style: { fontSize: "0.9vw" } }}
                variant="outlined"
                value={userData.foundersName}
                onChange={(e) => {
                  handleChange(e, "foundersName");
                }}
                placeholder="Founders Name"
                disableUnderline={true}
              />
              <Typography className={classes.title}>
                Date of Incorporation*:
              </Typography>
              <DatePicker
                value={
                  userData.dateOfIncorporation === new Date()
                    ? ""
                    : userData.dateOfIncorporation &&
                      userData.dateOfIncorporation.toString().split("T")[0]
                }
                onChange={(e) => {
                  handleChange(e, "dateOfIncorporation");
                }}
              />
              <Typography className={classes.title}>Pan Card No*:</Typography>
              <Input
                className={`${classes.text} ${classes.input}`}
                inputProps={{ style: { fontSize: "0.9vw" } }}
                variant="outlined"
                value={userData.pancard}
                onChange={(e) => {
                  handleChange(e, "pancard");
                }}
                placeholder="Pan Card No"
                disableUnderline={true}
              />

              <Typography className={classes.title}>G.S.T Number*:</Typography>
              <Input
                className={`${classes.text} ${classes.input}`}
                inputProps={{ style: { fontSize: "0.9vw" } }}
                variant="outlined"
                value={userData.gstNumber}
                onChange={(e) => {
                  handleChange(e, "gstNumber");
                }}
                placeholder="G.S.T Number"
                disableUnderline={true}
              />
              <Typography className={classes.title}>
                Accounts Head Name (optional):
              </Typography>
              <Input
                className={`${classes.text} ${classes.input}`}
                inputProps={{ style: { fontSize: "0.9vw" } }}
                variant="outlined"
                value={userData.accountsHeadName}
                onChange={(e) => {
                  handleChange(e, "accountsHeadName");
                }}
                placeholder="Accounts Head Name"
                disableUnderline={true}
              />
              <Typography className={classes.title}>
                Communication Head Name (optional):
              </Typography>
              <Input
                className={`${classes.text} ${classes.input}`}
                inputProps={{ style: { fontSize: "0.9vw" } }}
                variant="outlined"
                value={userData.communucationHeadName}
                onChange={(e) => {
                  handleChange(e, "communucationHeadName");
                }}
                placeholder="Communication Head Name "
                disableUnderline={true}
              />
            </Box>
            <Box
              sx={{
                display: "grid",
                width: "17vw",
                justifyContent: "space-between",
                marginLeft: "0.5vw",
              }}
            >
              <Typography className={classes.title}>Gender*:</Typography>

              <Select
                sx={{ fontSize: "0.9vw" }}
                value={userData.gender}
                variant="outlined"
                onChange={(e) => {
                  handleChange(e, "gender");
                }}
                size="small"
              >
                <MenuItem value={userData.gender}>{userData.gender}</MenuItem>
                {GenderTabs.map((gender, index) => {
                  if (userData.gender !== gender) {
                    return <MenuItem value={gender}>{gender}</MenuItem>;
                  } else {
                    return false;
                  }
                })}
              </Select>
              <Typography className={classes.title}>Address*:</Typography>
              <Input
                className={`${classes.text} ${classes.input}`}
                inputProps={{ style: { fontSize: "0.9vw" } }}
                variant="outlined"
                value={userData.address}
                onChange={(e) => {
                  handleChange(e, "address");
                }}
                placeholder="Address"
                disableUnderline={true}
              />
              <Typography className={classes.title}>State*:</Typography>
              <Input
                className={`${classes.text} ${classes.input}`}
                inputProps={{ style: { fontSize: "0.9vw" } }}
                variant="outlined"
                value={userData.state}
                onChange={(e) => {
                  handleChange(e, "state");
                }}
                placeholder="State"
                disableUnderline={true}
              />
              <Typography className={classes.title}>Pincode*:</Typography>
              <Input
                className={`${classes.text} ${classes.input}`}
                inputProps={{ style: { fontSize: "0.9vw" } }}
                variant="outlined"
                type="number"
                value={userData.pinCode}
                onChange={(e) => {
                  handleChange(e, "pinCode");
                }}
                placeholder="Pincode"
                disableUnderline={true}
              />

              <Typography className={classes.title}>
                Founder Contact Number*:
              </Typography>
              <Input
                className={`${classes.text} ${classes.input}`}
                inputProps={{ style: { fontSize: "0.9vw" } }}
                variant="outlined"
                type="number"
                value={userData.founderContactNumber}
                onChange={(e) => {
                  handleChange(e, "founderContactNumber");
                }}
                placeholder="Founder Contact Number"
                disableUnderline={true}
              />
              <Typography className={classes.title}>
                Registration Number*:
              </Typography>
              <Input
                className={`${classes.text} ${classes.input}`}
                inputProps={{ style: { fontSize: "0.9vw" } }}
                variant="outlined"
                value={userData.registrationNumber}
                onChange={(e) => {
                  handleChange(e, "registrationNumber");
                }}
                placeholder="Registration Number"
                disableUnderline={true}
              />
              <Typography className={classes.title}>
                F.C.R.A Registration Number*:
              </Typography>
              <Input
                className={`${classes.text} ${classes.input}`}
                inputProps={{ style: { fontSize: "0.9vw" } }}
                variant="outlined"
                value={userData.fcraRegNumber}
                onChange={(e) => {
                  handleChange(e, "fcraRegNumber");
                }}
                placeholder="F.C.R.A Registration Number"
                disableUnderline={true}
              />
              <Typography className={classes.title}>
                Focused Causes*:
              </Typography>
              <Input
                className={`${classes.text} ${classes.input}`}
                inputProps={{ style: { fontSize: "0.9vw" } }}
                variant="outlined"
                value={userData.focusedCourses}
                onChange={(e) => {
                  handleChange(e, "focusedCourses");
                }}
                placeholder="child causes,womencauses)"
                disableUnderline={true}
              />
              <Typography className={classes.title}>
                Accounts Head Contact No: (optional)
              </Typography>
              <Input
                className={`${classes.text} ${classes.input}`}
                inputProps={{ style: { fontSize: "0.9vw" } }}
                variant="outlined"
                type="number"
                value={userData.accountsHeadContact}
                onChange={(e) => {
                  handleChange(e, "accountsHeadContact");
                }}
                placeholder="Accounts Head Contact No"
                disableUnderline={true}
              />
              <Typography className={classes.title}>
                Communication Head Contact No: (optional)
              </Typography>
              <Input
                className={`${classes.text} ${classes.input}`}
                inputProps={{ style: { fontSize: "0.9vw" } }}
                variant="outlined"
                type="number"
                value={userData.communucationHeadContact}
                onChange={(e) => {
                  handleChange(e, "communucationHeadContact");
                }}
                placeholder="Communication Head Contact No"
                disableUnderline={true}
              />
            </Box>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ display: "grid", width: "12.5vw" }}>
              <Typography className={classes.title}>Name of Bank*:</Typography>
              <Input
                className={`${classes.text} ${classes.input}`}
                inputProps={{ style: { fontSize: "0.9vw" } }}
                variant="outlined"
                value={userData.bankName}
                onChange={(e) => {
                  handleChange(e, "bankName");
                }}
                placeholder=""
                disableUnderline={true}
              />
              <Typography className={classes.title}>
                Name of Account holder*:
              </Typography>
              <Input
                className={`${classes.text} ${classes.input}`}
                inputProps={{ style: { fontSize: "0.9vw" } }}
                variant="outlined"
                value={userData.nameOfAccountHolder}
                onChange={(e) => {
                  handleChange(e, "nameOfAccountHolder");
                }}
                placeholder=""
                disableUnderline={true}
              />
              <Typography className={classes.title}>I.F.S.C Code*:</Typography>
              <Input
                className={`${classes.text} ${classes.input}`}
                inputProps={{ style: { fontSize: "0.9vw" } }}
                variant="outlined"
                value={userData.ifscCode}
                onChange={(e) => {
                  handleChange(e, "ifscCode");
                }}
                placeholder=""
                disableUnderline={true}
              />
            </Box>
            <Box sx={{ display: "grid", width: "12.5vw" }}>
              <Typography className={classes.title}>
                Account Number*:
              </Typography>
              <Input
                type="number"
                className={`${classes.text} ${classes.input}`}
                inputProps={{ style: { fontSize: "0.9vw" } }}
                variant="outlined"
                value={userData.accountNumber}
                onChange={(e) => {
                  handleChange(e, "accountNumber");
                }}
                placeholder=""
                disableUnderline={true}
              />
              <Typography className={classes.title}>Branch*:</Typography>
              <Input
                className={`${classes.text} ${classes.input}`}
                inputProps={{ style: { fontSize: "0.9vw" } }}
                variant="outlined"
                value={userData.branch}
                onChange={(e) => {
                  handleChange(e, "branch");
                }}
                placeholder=""
                disableUnderline={true}
              />
            </Box>
          </Box>
          <Typography className={classes.othertext}>
            Other details for online payment
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ display: "grid", width: "12.5vw" }}>
              <Typography className={classes.title}>G-Pay number</Typography>
              <Input
                type="number"
                className={`${classes.text} ${classes.input}`}
                inputProps={{ style: { fontSize: "0.9vw" } }}
                variant="outlined"
                value={userData.gpayNumber}
                onChange={(e) => {
                  handleChange(e, "gpayNumber");
                }}
                placeholder=""
                disableUnderline={true}
              />
            </Box>
            <Box sx={{ display: "grid", width: "12.5vw" }}>
              <Typography className={classes.title}>Paytm Number:</Typography>
              <Input
                type="number"
                className={`${classes.text} ${classes.input}`}
                inputProps={{ style: { fontSize: "0.9vw" } }}
                variant="outlined"
                value={userData.paytmNumber}
                onChange={(e) => {
                  handleChange(e, "paytmNumber");
                }}
                placeholder=""
                disableUnderline={true}
              />
            </Box>
          </Box>
        </React.Fragment>
      )}
      <Button
        className={classes.NgoSaveButton}
        onClick={() => {
          handleSubmit();
        }}
        fullWidth
        sx={{ background: "#FAC11C", borderRadius: "12px", marginTop: "5%" }}
      >
        Save
      </Button>
      <Button
        fullWidth
        onClick={() => {
          setEdit(!edit);
        }}
        sx={{
          border: "1px solid #DCDCEB",
          background: "#FFFFFF",
          borderRadius: "12px",
          marginTop: "2%",
          color: "black",
          marginBottom: "5%",
        }}
      >
        Cancel
      </Button>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    userData: state.usersReducer.results,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    listAllNgo: (page) => dispatch({ type: "NGO_LIST", value: page }),
    editNgo: (userData) => dispatch({ type: "EDIT_NGO", value: userData }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(NgoEdit);
