import Box from "@mui/material/Box";
import React, { useState } from "react";
import MediaList from "./mediaList";
import MediaPopup from "./mediaPopup";
const MediaPlayer = (props) => {
  const { images, videos } = props;
 
  const [modal, setModal] = useState(false);
  return (
    <Box
      sx={{
        width: "38vw",
        height: "25vh",
        display: "flex",
        marginTop: "3%",
      }}
    >
      {modal === true ? (
        <MediaPopup
          modal={modal}
          setModal={setModal}
          images={images}
          videos={videos}
        />
      ) : (
        ""
      )}
      {images.length > videos.length
        ? images.map((image, index) => {
            return (
              <MediaList
                key={index}
                image={image}
                index={index}
                video={""}
                videos={videos}
                modal={modal}
                setModal={setModal}
                images={images}
              />
            );
          })
        : videos.map((video, index) => {
            return (
              <MediaList
                key={index}
                image={""}
                index={index}
                video={video}
                videos={videos}
                modal={modal}
                setModal={setModal}
                images={images}
              />
            );
          })}
    </Box>
  );
};
export default MediaPlayer;
