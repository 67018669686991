import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  ListItemIcon,
  Button,
  Tab,
  Tabs,
} from "@mui/material";
import React from "react";
import { Box } from "@mui/system";
import ngoImage from "../../assets/images/profile-image";
import editIcon from "../../assets/images/edit.png";
import { ngoProfileStyles } from "./ngoProfileStyles";
import closeIcon from "../../assets/images/close.png";
import {
  colorPicker,
  NgoEditFields,
  ngoPopupTabs,
} from "../../constants/constants";
import NgoFields from "./ngoFields";
import NgoComments from "./ngoComments";
import ConfirmationDialog from "../dialogBox/confirmationDialog";
import { useState } from "react";
import lockIcon from "../../assets/images/lock-icon.png";
import approveIcon from "../../assets/images/approve.png";
import deleteIcon from "../../assets/images/delete-menu.png";
import { useEffect } from "react";
import NgoTransaction from "./ngoTransaction";

const NgoProfile = ({
  dialog,
  setDialog,
  data,
  deleteResponse,
  handleApprove,
  handleDelete,
  title,
  image,
  status,
  addComments,
  page
}) => {
  const [modal, setModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [value, setValue] = React.useState(0);
  const [tabselector, setTabselector] = React.useState(ngoPopupTabs);
  useEffect(() => {
    if (edit) {
      setTabselector(NgoEditFields);
      setValue(0);
    } else {
      setTabselector(ngoPopupTabs);
    }
  }, [edit]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const classes = ngoProfileStyles();
  return (
    <React.Fragment>
      <ConfirmationDialog
        submit={status === "approve" ? handleApprove : handleDelete}
        image={image}
        title={title}
        modal={modal}
        setModal={setModal}
      />
      <Dialog
        sx={{ overflowX: "hidden" }}
        className={classes.dialog}
        open={dialog}
        onClose={() => {
          setDialog(!dialog);
        }}
      >
        <DialogContent
          sx={{
            padding: "3% 5% 0% 5%",
            height: "100vh",
            background: "white",
            marginRight: "0%",
            marginTop: "1%",
            overflowX: "hidden",
          }}
        >
          <ListItemIcon
            sx={{
              display: "flex",
              flexDirection: "row-reverse",
              marginBottom: "5%",
            }}
          >
            <img
              src={closeIcon}
              alt={closeIcon}
              style={{ cursor: "pointer", width: "1vw", height: "2vh" }}
              onClick={() => {
                setDialog(!dialog);
              }}
            />
          </ListItemIcon>
          <DialogActions
            sx={{ WebkitJustifyContent: "flex-start", padding: "0%" }}
          >
            <img
              style={{ borderRadius: "100%", marginRight: "5%" }}
              width="60vw"
              height="60vh"
              src={data.profilePic || ngoImage}
              alt={data.profilePic || ngoImage}
            />
            <Box>
              <Typography
                sx={{
                  fontSize: "1.25vw",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  color: "#23190C",
                }}
              >
                {data.name}
              </Typography>
              <Typography
                sx={{
                  fontSize: "0.9vw",
                  color: "#808080",
                  fontWeight: "bold",
                  width: "max-content",
                }}
              >
                {data.lastModifiedDate
                  ? `Updated On ${data.lastModifiedDate}`
                  : ""}
              </Typography>
              <Box
                sx={{
                  background: colorPicker(data.status),
                  borderRadius: "30px",
                  padding: "2% 10% 2% 10%",
                  color: "white",
                  width: "max-content",
                  fontSize: "0.9vw",
                  textTransform: "capitalize",
                  marginTop: "3%",
                }}
              >
                {data.status}
              </Box>
            </Box>

            {edit ? (
              ""
            ) : (
              <React.Fragment>
                <Typography
                  sx={{
                    fontSize: "1.1vw",
                    marginLeft: "52%",
                  }}
                >
                  Edit
                </Typography>
                <img
                  onClick={() => {
                    setEdit(true);
                  }}
                  height="20vh"
                  width="20vw"
                  style={{
                    cursor: "pointer",
                  }}
                  src={editIcon}
                  alt={editIcon}
                />
              </React.Fragment>
            )}
          </DialogActions>
          {data.status !== "verified" ? (
            <Button
              onClick={() => {
                handleApprove("approve");
              }}
              className={`${classes.button} ${classes.approve}`}
            >
              <img src={approveIcon} alt={approveIcon} />
              &nbsp; Approve
            </Button>
          ) : (
            ""
          )}
          {data.status !== "blocked" ? (
            <Button
              onClick={() => {
                handleApprove("block");
              }}
              className={`${classes.button} ${classes.block}`}
            >
              <img src={lockIcon} alt={lockIcon} />
              &nbsp; Block
            </Button>
          ) : (
            ""
          )}

          <Button
            onClick={() => {
              handleDelete();
            }}
            className={`${classes.button} ${classes.delete}`}
          >
            <img src={deleteIcon} alt={deleteIcon} />
            &nbsp; Delete
          </Button>

          <Box sx={{ width: "100%" }}>
            <Box className={classes.tabContainer}>
              <Tabs
                className={classes.tabs}
                value={value}
                onChange={handleChange}
              >
                {tabselector.map((fields, index) => {
                  return (
                    <Tab key={index} className={classes.tab} label={fields} />
                  );
                })}
              </Tabs>
            </Box>
            {value === 0 || value === 2 || edit ? (
              <NgoFields
                handleApprove={handleApprove}
                edit={edit}
                setEdit={setEdit}
                value={value}
                data={data}
                page={page}
              />
            ) : (
              ""
            )}
            {value === 4 ? (
              <NgoComments
                setDialog={setDialog}
                dialog={dialog}
                value={value}
                data={data}
                addComments={addComments}
              />
            ) : (
              ""
            )}
            {value === 3 ? <NgoTransaction data={data} /> : ""}
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};
export default NgoProfile;
