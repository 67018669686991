import React from "react";
import {
  Box,
  TableCell,
  TableRow,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import more from "../../assets/images/more.png";
import { connect } from "react-redux";
import {
  approveSettlementMessage,
  approveRefundMessage,
} from "../../constants/constants";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import refundImage from "../../assets/images/refund.png";
import approveImage from "../../assets/images/approve-image.png";
import DialogBox from "../dialogBox/confirmationDialog";
import exportImage from "../../assets/images/export.png";
import { colorChange } from "../../constants/constants";

let title = "";
let image = "";
let status = "";

const DonationContainer = (props) => {
  const navigate = useNavigate();
  const [projectData, setProjectData] = useState();
  const [feedback, setFeedback] = useState("");
  const [modal, setModal] = useState(false);
  const [item, setItem] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setProjectData(data);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleRefund = () => {
    status = "";
    if (modal) {
      props.donationRefund(item.ngo_id, item.payment_id);
      props.loadingMedia(true)
      let timer = setTimeout(() => {
        props.listAllTransactions();
        navigate("/donationHistory");
        setModal(!modal);
      }, 500);
      return () => clearTimeout(timer);
    } else {
      title = approveRefundMessage;
      image = approveImage;
      status = "Refund";
      setModal(!modal);
    }
  };
  const handleSettle = () => {
    if (modal) {
      console.log("enter");
      props.donationSettle(item.ngo_id, item.payment_id);
      props.loadingMedia(true)
      let timer = setTimeout(() => {
        props.listAllTransactions();
        navigate("/donationHistory");
        setModal(!modal);
      }, 9000);
      return () => clearTimeout(timer);
    } else {
      title = approveSettlementMessage;
      image = approveImage;
      status = "Settle";
      setModal(!modal);
    }
  };
  const { data } = props;
  console.log(data);
  return (
    <TableRow
      key={data.title}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <DialogBox
        status={status}
        submit={status === "Refund" ? handleRefund : handleSettle}
        image={image}
        title={title}
        modal={modal}
        setModal={setModal}
      />
      <TableCell
        sx={{ textTransform: "uppercase", fontSize: "0.9vw" }}
        align="left"
      >
        <strong>{data.donor_name}</strong>
      </TableCell>
      <TableCell
        sx={{ textTransform: "uppercase", fontSize: "0.9vw" }}
        align="left"
      >
        <strong>{data.ngo_name}</strong>
      </TableCell>
      <TableCell
        sx={{ textTransform: "uppercase", fontSize: "0.9vw" }}
        align="left"
      >
        <strong>{data.amount}</strong>
      </TableCell>
      <TableCell
        sx={{ textTransform: "uppercase", fontSize: "0.9vw" }}
        align="left"
      >
        <strong>{data.createdDate}</strong>
      </TableCell>
      <TableCell
        sx={{ textTransform: "uppercase", fontSize: "0.9vw" }}
        align="left"
      >
        <strong>{data.blog_title}</strong>
      </TableCell>
      <TableCell
        sx={{ textTransform: "uppercase", fontSize: "0.9vw" }}
        align="left"
      >
        <strong>{data.method}</strong>
      </TableCell>
      <TableCell
        sx={{ textTransform: "uppercase", fontSize: "0.9vw" }}
        align="left"
      >
        <Box
          sx={{
            background: colorChange(data.status)[0],
            borderRadius: "30px",
            textTransform: "uppercase",
            color: "#ffffff",
            textAlign: "center",
            padding: "5% 5% 5% 5%",
            alignItems: "center",
            display: "flex",
            width: "8vw",
          }}
        >
          <img
            style={{
              borderRadius: "100%",
              marginRight: "5%",
              marginLeft: "5%",
            }}
            src={colorChange(data.status)[1]}
            alt={colorChange(data.status)[1]}
          />
          {data.status === "created" ? "pending" : data.status}
        </Box>
      </TableCell>

      <TableCell align="left">
        <img
          style={{ cursor: "pointer" }}
          onClick={handleClick}
          src={more}
          alt={more}
        />
        {data.status === "pending" || data.status === "created" ? (
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            autoFocus={false}
          >
            <MenuItem
              onClick={(e) => {
                setItem(data);
                handleRefund();
              }}
            >
              <ListItemIcon>
                <img src={refundImage} alt={refundImage} />
              </ListItemIcon>
              Refund
            </MenuItem>
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                setItem(data);
                handleSettle();
              }}
            >
              <ListItemIcon>
                <img src={exportImage} alt={exportImage} />
              </ListItemIcon>
              Settle
            </MenuItem>
          </Menu>
        ) : (
          ""
        )}
      </TableCell>
    </TableRow>
  );
};
const mapStateToProps = (state) => {
  return {
    loadingMediaResponse: state.projectReducer.loadingMedia,
    transactionResponse: state.transactionsReducer.allDonations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadingMedia: (loader) =>
      dispatch({ type: "LOADING_MEDIA", value: loader }),
    donationRefund: (ngoId, paymentId) =>
      dispatch({
        type: "DONATION_REFUND",
        value: { ngo_id: ngoId, payment_id: paymentId },
      }),
    donationSettle: (ngoId, paymentId) =>
      dispatch({
        type: "DONATION_SETTLE",
        value: { ngo_id: ngoId, payment_id: paymentId },
      }),

    listAllTransactions: () => dispatch({ type: "LIST_TRANSACTIONS" }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DonationContainer);
