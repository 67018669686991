import React from "react";
import Header from "../../components/header/header";
import Navbar from "../../components/navbar/navbar";
import { Card, Box, Typography, Button, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useStyles } from "../../components/projectListContainer/projectListContainerStyle";
import NewsContainer from "../../components/news/newsListContainer";

const NewsArticles = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  return (
    <Box>
      <Navbar active="News Articles" />
      <Header heading="News Articles" />
      <Card
        sx={{
          margin: "0% 0% 0% 11.7%",
          padding: "3% 3% 9% 5%",
          border: "1px solid #E5E5E5",
          borderRadius: "15px",
        }}
      >
        <Card>
          <Box
            sx={{
              justifyContent: "space-between",
              padding: "1% 3% 1% 3%",
              alignItems: "center",
            }}
            display="flex"
          >
            <Typography sx={{ fontSize: "125%", fontWeight: "400" }}>
              News
            </Typography>
            <Button
              sx={{ color: "black", padding: "0%" }}
              onClick={() => {
                navigate("/newsarticles/create");
              }}
              className={classes.newButton}
            >
              <svg width="16" height="26" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.06543 7.50879C5.06543 6.9565 4.61771 6.50879 4.06543 6.50879H1.89697C1.39296 6.50879 0.984375 6.10021 0.984375 5.59619V5.59619C0.984375 5.09218 1.39296 4.68359 1.89697 4.68359H4.06543C4.61771 4.68359 5.06543 4.23588 5.06543 3.68359V1.42285C5.06543 0.913175 5.4786 0.5 5.98828 0.5V0.5C6.49796 0.5 6.91113 0.913175 6.91113 1.42285V3.68359C6.91113 4.23588 7.35885 4.68359 7.91113 4.68359H10.0898C10.5939 4.68359 11.0024 5.09218 11.0024 5.59619V5.59619C11.0024 6.10021 10.5939 6.50879 10.0898 6.50879H7.91113C7.35885 6.50879 6.91113 6.9565 6.91113 7.50879V9.74902C6.91113 10.2587 6.49796 10.6719 5.98828 10.6719V10.6719C5.4786 10.6719 5.06543 10.2587 5.06543 9.74902V7.50879Z" fill="#23190C" />
              </svg>

            </Button>
          </Box>
          <Divider />
          <NewsContainer />
        </Card>
      </Card>
    </Box>
  );
};
export default NewsArticles;
