import {
  Box,
  Button,
  Input,
  Typography,
  MenuItem,
  Select,
} from "@mui/material";
// import { useEffect } from "react";
import { useState } from "react";
import { ngoProfileStyles } from "../ngoProfile/ngoProfileStyles";
import { connect } from "react-redux";
import React from "react";
import { useNavigate } from "react-router-dom";
import ConfirmationDialog from "../dialogBox/confirmationDialog";
import { EditProjectMessage } from "../../constants/constants";
import approveIcon from "../../assets/images/approve-image.png";
import { DatePicker } from "../datepicker/datepicker";
import { countries } from "../../assets/countries";
import { GenderTabs } from "../../constants/constants";
let status = "";
let title = "";
let image = "";
const NgoEdit = ({ data, edit, setEdit, editNgo, listAllDonors }) => {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [userData, setUserData] = useState(data);
  const handleSubmit = () => {
    if (modal) {
      userData.type = "donor";
      setEdit(false);
      editNgo(userData);
      const timer = setTimeout(() => {
        listAllDonors();
        navigate("/dashboard/details", { state: { header: "Total N.G.O.s" } });
      }, 200);
      return () => clearTimeout(timer);
    } else {
      setModal(!modal);
      image = approveIcon;
      title = EditProjectMessage;
      status = "approve";
    }
  };
  const handleChange = (event, formFields) => {
    console.log(event);
    setUserData((prevState) => ({
      ...prevState,
      [formFields]: event.target.value,
    }));
  };

  const classes = ngoProfileStyles();
  return (
    <React.Fragment>
      <ConfirmationDialog
        submit={status === "approve" ? handleSubmit : ""}
        image={image}
        title={title}
        modal={modal}
        setModal={setModal}
      />

      <Box sx={{ display: "grid", alignItems: "center" }}>
        <Typography className={classes.title}>Name:</Typography>
        <Input
          className={`${classes.text} ${classes.input}`}
          inputProps={{ style: { fontSize: "0.9vw", width: "15vw" } }}
          variant="outlined"
          fullWidth
          value={userData.name}
          onChange={(e) => {
            handleChange(e, "name");
          }}
          disableUnderline={true}
        />
        <Typography className={classes.title}>Email Address:</Typography>
        <Input
          disabled
          className={`${classes.text} ${classes.input}`}
          inputProps={{ style: { fontSize: "0.9vw" } }}
          variant="outlined"
          value={userData.emailAddress}
          onChange={(e) => {
            handleChange(e, "emailAddress");
          }}
          disableUnderline={true}
        />
        <Typography className={classes.title}>Gender:</Typography>

        <Select
                sx={{ fontSize: "0.9vw"}}
                value={userData.gender}
                variant="outlined"
                onChange={(e) => {
                  handleChange(e, "gender");
                }}
                size="small"
              >
          <MenuItem value={userData.gender}>{userData.gender}</MenuItem>
          {GenderTabs.map((gender, index) => {
            if (userData.gender !== gender) {
              return <MenuItem value={gender}>{gender}</MenuItem>;
            } else {
              return false;
            }
          })}
        </Select>

        <Typography className={classes.title}>D.O.B:</Typography>

        <DatePicker
          value={
            userData.age === new Date()
              ? ""
              : userData.age && userData.age.toString().split("T")[0]
          }
          onChange={(e) => {
            handleChange(e, "age");
          }}
        />

        {/*       <Input
              className={`${classes.text} ${classes.input}`}
              inputProps={{ style: { fontSize: "0.9vw" } }}
              variant="outlined"
              value={userData.age}
              onChange={(e) => {
                handleChange(e, "age");
              }}
              disableUnderline={true}
            /> */}
        <Typography className={classes.title}>Phone:</Typography>
        <Input
          className={`${classes.text} ${classes.input}`}
          inputProps={{ style: { fontSize: "0.9vw" } }}
          variant="outlined"
          value={userData.contactNumber}
          onChange={(e) => {
            handleChange(e, "contactNumber");
          }}
          disableUnderline={true}
        />
        <Typography className={classes.title}>Country:</Typography>
        <Select
          value={userData.country}
          sx={{ fontSize: "0.9vw" }}
          onChange={(e) => {
            handleChange(e, "country");
          }}
          size="small"
        >
          <MenuItem inputProps={{ fontSize: "0.9vw" }} value="default">
            {userData.country}
          </MenuItem>
          {countries &&
            countries.map((country, code) => (
              <MenuItem value={country.name}>{country.name}</MenuItem>
            ))}
        </Select>
        <Typography className={classes.title}>Location:</Typography>
        <Input
          className={`${classes.text} ${classes.input}`}
          inputProps={{ style: { fontSize: "0.9vw" } }}
          variant="outlined"
          value={userData.location}
          onChange={(e) => {
            handleChange(e, "location");
          }}
          disableUnderline={true}
        />
        <Typography className={classes.title}>Interest Courses:</Typography>
        <Input
          className={`${classes.text} ${classes.input}`}
          inputProps={{ style: { fontSize: "0.9vw" } }}
          variant="outlined"
          value={userData.interstCourses}
          onChange={(e) => {
            handleChange(e, "interstCourses");
          }}
          disableUnderline={true}
        />
      </Box>
      <Button
        className={classes.donorSaveButton}
        onClick={() => {
          handleSubmit();
        }}
        sx={{
          background: "#FAC11C",
          borderRadius: "12px",
          marginTop: "5%",
          marginLeft: "15%",
          width: "10vw",
        }}
      >
        Save
      </Button>
      <Button
        className={classes.donorCancelButton}
        onClick={() => {
          setEdit(!edit);
        }}
        sx={{
          background: "#FAC11C",
          borderRadius: "12px",
          marginTop: "5%",
          marginLeft: "15%",
          width: "10vw",
        }}
      >
        Cancel
      </Button>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    userData: state.usersReducer.results,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    listAllNgo: () => dispatch({ type: "NGO_LIST" }),
    editNgo: (userData) => dispatch({ type: "EDIT_NGO", value: userData }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(NgoEdit);
