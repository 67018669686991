import React, { useState } from "react";
import Navbar from "../../../components/navbar/navbar";
import "./listProjects.scss";
import Header from "../../../components/header/header";
import { connect } from "react-redux";
import ProjectListContainer from "../../../components/projectListContainer/projectListContainer";
import Lottie from "react-lottie";
import gifReader from "../../../assets/images/loading.json";
import { Card, Box } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: gifReader,
  renderer: "svg",
};
const ListProjects = (props) => {
  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
    props.loadingMedia(true);
    props.listAllProjects(page, "project");
  };
  useState(() => {
    props.clearImpactAndUpdate();
    if (!props.loginResponse.results) {
      props.auth();
    }
    if (
      !props.projectListResponse.results ||
      page === 1 ||
      props.projectListResponse.type === "news"
    ) {
      props.loadingMedia(true);
      props.listAllProjects(page, "project");
    }
  }, [props]);

  const { projectListResponse } = props;
  return (
    <React.Fragment>
      <Navbar active="Projects" />

      <Header heading="PROJECTS" />
      <Card
        sx={{
          margin: "0% 0% 0% 11.7%",
          padding: "0% 3% 9% 5%",
          border: "1px solid #E5E5E5",
          borderRadius: "15px",
        }}
      >
        {projectListResponse.type === "project" ? (
          <React.Fragment>
            {props.loadingMediaResponse ? (
              <Lottie options={defaultOptions} height={500} width={500} />
            ) : (
              <ProjectListContainer
                page={page}
                projectList={projectListResponse.results}
              />
            )}

            <Box
              sx={{
                display: "flex",
                flexDirection: "row-reverse",
                marginTop: "2%",
              }}
            >
              <Stack spacing={2}>
                <Pagination
                  count={Math.ceil(projectListResponse.totalCount / 10)}
                  siblingCount={1}
                  boundaryCount={1}
                  page={page}
                  onChange={handleChange}
                />
              </Stack>
            </Box>
          </React.Fragment>
        ) : (
          <Lottie options={defaultOptions} height={500} width={500} />
        )}
      </Card>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    projectListResponse: state.projectReducer.results,
    createStatus: state.projectReducer.create,
    loginResponse: state.loginReducer.results,
    deleteResponse: state.projectReducer.delete,
    editResponse: state.projectReducer.edit,
    loadingMediaResponse: state.projectReducer.loadingMedia,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    listAllProjects: (page, type) =>
      dispatch({ type: "PROJECT_LIST", value: { type: type, page: page } }),
    auth: () => dispatch({ type: "AUTH" }),
    clearImpactAndUpdate: () => dispatch({ type: "CLEAR_IMPACT_UPDATE" }),
    loadingMedia: (loader) =>
      dispatch({ type: "LOADING_MEDIA", value: loader }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ListProjects);
