const initialState = {
  results: [],
  create: [],
  upload1: {},
  upload2: {},
  edit: {},
  delete: [],
  update: [],
  impact: [],
  loadingMedia: false,
  loadingDoc: false,
  projectNewDetails: {},
  removeMedia: {},
  dashboardAction: "",
  projectTab: "",
  selectedTab:"",
};

const projectReducer = (state = initialState, action) => {
  const newState = { ...state };

  switch (action.type) {
    case "SELECTED_TAB":
      newState.selectedTab = action.value;
      break;
    case "DASHBOARD_ACTION":
      newState.dashboardAction = action.value;
      break;
    case "PROJECT_TAB":
      newState.projectTab = action.value;
      break;
    case "REMOVE_UPLOAD_CONTENT":
      newState.upload1 = {};
      newState.upload2 = {};
      newState.removeMedia = {};
      newState.edit = {};
      break;
    case "REMOVE_MEDIA_ASYNC":
      newState.removeMedia = action.value;
      break;
    case "CLEAR_IMPACT_UPDATE":
      newState.update = [];
      newState.impact = [];
      break;
    case "LOADING_MEDIA":
      newState.loadingMedia = action.value;
      break;
    case "LOADING_DOC":
      newState.loadingDoc = action.value;
      break;
    case "CREATE_PROJECT_ASYNC":
      newState.create = action.value;
      break;
    case "UPDATE_PROJECT_ASYNC":
      newState.update = action.value;
      break;
    case "IMPACT_PROJECT_ASYNC":
      newState.impact = action.value;
      break;
    case "CREATE_UPDATE_IMPACT_PROJECT_ASYNC":
      newState.projectNewDetails = action.value;
      break;
    case "REMOVE_UPDATE_IMPACT_PROJECT_ASYNC":
      newState.projectNewDetails = {};
      break;
    case "DELETE_PROJECT_ASYNC":
      newState.delete = action.value;
      break;
    case "EDIT_PROJECT_ASYNC":
      newState.edit = action.value;
      break;
    case "PROJECT_LIST_ASYNC":
      newState.results = action.value;
      break;
    case "UPLOAD1_ASYNC":
      newState.upload1 = action.value.results;
      break;
    case "UPLOAD2_ASYNC":
      newState.upload2 = action.value.results;
      break;
    default:
      return newState;
  }
  return newState;
};

export default projectReducer;
