import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "../projectForm/projectForm.scss";

import backButton from "../../assets/images/back.svg";
import uploadImg from "../../assets/images/uploadImg.png";
import {
  useNavigate,
  useLocation,
  createRoutesFromChildren,
} from "react-router-dom";
import uploadImg2 from "../../assets/images/upldimg2.png";
import closeIcon from "../../assets/images/close.png";
import { DatePicker } from "../datepicker/datepicker";
import Lottie from "react-lottie";
import spinner from "../../assets/images/loadingSpinner.json";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Card, Divider, Typography } from "@mui/material";
import { Box } from "@mui/material";
import deleteIcon from "../../assets/images/delete-image.png";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import DialogBox from "../dialogBox/confirmationDialog";

import {
  deleteProjectMessage,
  CreateProjectMessage,
  EditProjectMessage,
} from "../../constants/constants";
import { pdfjs } from "react-pdf";
import approveImage from "../../assets/images/approve-image.png";
import gifReader from "../../assets/images/loading.json";
import Preview from "../preview/projectPreview";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import dragImage from "../../assets/images/drag.png";
document.addEventListener("contextmenu", (event) => {
  event.preventDefault();
});
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
window.Buffer = window.Buffer || require("buffer").Buffer;
let upload1Type = "";
let upload2Type = "";
let title = "";
let image = "";
let status = "";
let urlMedia = "";
let createdResponse = {};
let docs = [{ uri: "", fileType: "" }];
const pageloaderOptions = {
  loop: true,
  autoplay: true,
  animationData: gifReader,
  renderer: "svg",
};
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: spinner,
  renderer: "svg",
};
const ProjectForm = (props) => {
  const [modal, setModal] = useState(false);
  const [preview, setPreview] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const [projectFields, setProjectFields] = useState(
    (location.state && location.state.editprojectDetails) || props
  );
  console.log("------", location.state && location.state.editprojectDetails);
  console.log("imageMedia", projectFields.imageMedia);
  const [characters, updateCharacters] = useState(projectFields.imageMedia);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/login");
    }
    if (props.editResponse.results) {
      localStorage.setItem("previewId", props.editResponse.results._id);
      createdResponse = props.editResponse.results;
      props.listAllProjects(1, "news");
      setPreview(true);
      props.removeUploadContent();
    }
    if (props.createResponse.results) {
      props.listAllProjects(1, "news");
      localStorage.setItem("previewId", props.createResponse.results._id);
      createdResponse = props.createResponse.results;
      props.removeUploadContent();
      setPreview(true);
    }
    if (props.removeMediaResponse.results) {
      setProjectFields(props.removeMediaResponse.results);
      props.removeUploadContent();
    }
    if (props.upload1Response.Location) {
      if (upload1Type.match("image")) {
        let newObject = { ...projectFields };
        upload1Type = "";
        newObject.imageMedia.push(props.upload1Response.Location);
        setProjectFields(newObject);
      }
      if (upload1Type.match("video")) {
        let newObject = { ...projectFields };
        upload1Type = "";
        newObject.videoMedia.push(props.upload1Response.Location);
        setProjectFields(newObject);
      }
      if (upload1Type.match("audio")) {
        upload1Type = "";
        let newObject = { ...projectFields };
        newObject.audioMedia.push(props.upload1Response.Location);
        setProjectFields(newObject);
      }
      props.removeUploadContent();
    }
    if (props.upload2Response.Location) {
      if (upload2Type.match("application")) {
        upload2Type = "";
        let newObject = { ...projectFields };
        newObject.otherMedia.push(props.upload2Response.Location);
        setProjectFields(newObject);
      }
      props.removeUploadContent();
    } // eslint-disable-next-line
  }, [navigate, props, location]); // eslint-disable-next-line

  const [editorState, setEditorState] = useState(() => {
    if (projectFields.description !== "") {
      const blocksFromHtml = htmlToDraft(projectFields.description);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      return EditorState.createWithContent(contentState);
    } else return EditorState.createEmpty();
  });

  const onSetEditorState = (newState) => {
    setEditorState(newState);
  };
  const onhandleEditorState = (newState, formFields) => {
    const rawDraftContentState = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );

    setProjectFields((prevState) => ({
      ...prevState,
      [formFields]: rawDraftContentState,
    }));
  };
  const handleUpload = (event, upload) => {
    if (upload === "upload1") {
      props.loadingMedia(true);
      upload1Type = event.target.files[0].type;
      props.upload1(event.target.files[0]);
    } else {
      props.loadingDocs(true);
      upload2Type = event.target.files[0].type;
      props.upload2(event.target.files[0]);
    }
  };

  const handleChange = (event, formFields) => {
    event.preventDefault();
    setProjectFields((prevState) => ({
      ...prevState,
      [formFields]: event.target.value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    upload1Type = "";
    upload2Type = "";

    if (location.state && location.state.editprojectDetails) {
      props.loadingMedia(true);
      console.log(createdResponse);
      projectFields.type = "news";
      setProjectFields(projectFields);
      props.editProject(projectFields);
    } else {
      projectFields.type = "news";
      setProjectFields(projectFields);
      props.postProject(projectFields);
    }
  };
  const handleOnDragEnd = (result) => {
    const items = Array.from(characters);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    updateCharacters(items);
    // console.log("result", result);
    // const items = Array.from(projectFields.imageMedia);
    // console.log("items".items);
    // const [reorderedItem] = items.splice(result.source.index, 1);
    // console.log("Rendereditems", reorderedItem);
    // items.splice(result.destination.index, 0, reorderedItem);
    // console.log("items", items);
    // projectFields.imageMedia.splice(
    //   0,
    //   projectFields.imageMedia.length,
    //   ...items
    // );
    // console.log("console inside handle",projectFields.imageMedia)
    // setProjectFields([projectFields]);
  };
  const handleDelete = (url, type) => {
    if (modal) {
      if (status === "image") {
        let media = {
          src: urlMedia,
          type: status,
          bId: projectFields.b_id,
          documentType: "blog",
        };
        props.removeMedia(media);
        props.loadingMedia(true);
        const timer = setTimeout(() => {
          props.listAllProjects(1, "news");
          setModal(!modal);
        }, 200);
        return () => clearTimeout(timer);
      }
      if (status === "video") {
        let media = {
          src: urlMedia,
          type: status,
          bId: projectFields.b_id,
          documentType: "blog",
        };
        props.removeMedia(media);
        const timer = setTimeout(() => {
          props.listAllProjects(1, "news");
          setModal(!modal);
        }, 200);
        return () => clearTimeout(timer);
      }
      if (status === "audio") {
        let media = {
          src: urlMedia,
          type: status,
          bId: projectFields.b_id,
          documentType: "blog",
        };
        props.removeMedia(media);
        const timer = setTimeout(() => {
          props.listAllProjects(1, "news");
          setModal(!modal);
        }, 200);
        return () => clearTimeout(timer);
      }
      if (status === "file") {
        let media = {
          src: urlMedia,
          type: status,
          bId: projectFields.b_id,
          documentType: "blog",
        };
        props.removeMedia(media);
        props.loadingMedia(true);
        const timer = setTimeout(() => {
          props.listAllProjects(1, "news");
          setModal(!modal);
        }, 200);
        return () => clearTimeout(timer);
      }
    } else {
      urlMedia = url;
      title = deleteProjectMessage;
      image = deleteIcon;
      status = type;
      setModal(!modal);
    }
  };
  return (
    <Card
      sx={{
        borderRadius: "21px",
        border: "1px solid #EBEBEB",
        margin: "3% 2% 1% 0%",
      }}
    >
      {modal ? (
        <DialogBox
          status={status}
          submit={handleDelete}
          handleDelete={handleDelete}
          image={image}
          title={title}
          modal={modal}
          setModal={setModal}
        />
      ) : (
        ""
      )}
      {props.loadingMediaResponse && upload1Type === "" ? (
        <Lottie options={pageloaderOptions} height={500} width={500} />
      ) : preview ? (
        <Preview
          type="Newsarticles"
          preview={preview}
          setPreview={setPreview}
          projectFields={location.state && location.state.editprojectDetails}
          createdProject={createdResponse}
        />
      ) : (
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
          className="project-header3 left"
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img
              className="back-button"
              onClick={() => {
                !location.state
                  ? navigate("/newsArticles")
                  : navigate(
                    "/newsArticles/" + location.state.editprojectDetails.b_id,
                    {
                      state: {
                        projectDetails: location.state.editprojectDetails,
                      },
                    }
                  );
              }}
              style={{ cursor: "pointer", height: "2vh", width: "1.2vw" }}
              src={backButton}
              alt={backButton}
            />
            <Typography sx={{ fontSize: "1.25vw" }}>
              {location.state ? "Edit a News" : "Create a News"}
            </Typography>
          </Box>

          <div className="inline">
            <div>
              <label className="title-input">Title</label>
              <textarea
                onChange={(e) => {
                  handleChange(e, "title");
                }}
                value={projectFields.title}
                maxLength={180}
                type="text"
                placeholder="Type something"
                rows={4}
                className="textarea-input"
                required
              />
              <label className="title-input">Featured Media</label>
              <div className="uploadFile">
                {props.loadingMediaResponse && upload1Type !== "" ? (
                  <Lottie options={defaultOptions} height={50} width={50} />
                ) : (
                  <img
                    className="upload"
                    src={uploadImg}
                    alt={uploadImg}
                    style={{ border: "0px" }}
                  />
                )}
                <input
                  placeholder="browse"
                  id="inputTag"
                  type="file"
                  onChange={(event) => {
                    handleUpload(event, "upload1");
                  }}
                />
              </div>
              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="characters">
                  {(provided) => (
                    <Box {...provided.droppableProps} ref={provided.innerRef}>
                      {characters.map((image, index) => {
                        return (
                          <Draggable
                            key={index}
                            draggableId={image}
                            index={index}
                          >
                            {(provided) => (
                              <React.Fragment>
                                <Box
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  key={index}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "5%",
                                  }}
                                >
                                  <img src={dragImage} alt={dragImage} />
                                  <Box
                                    sx={{
                                      width: "30%",
                                      height: "100px",
                                      marginTop: "5%",
                                      marginLeft: "3%",
                                    }}
                                  >
                                    <img
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                      }}
                                      src={image}
                                      alt={image}
                                    />
                                  </Box>
                                  <img
                                    onClick={() => {
                                      handleDelete(image, "image");
                                    }}
                                    style={{
                                      cursor: "pointer",
                                      marginLeft: "29%",
                                      position: "absolute",
                                    }}
                                    height="15vh"
                                    src={closeIcon}
                                    alt={closeIcon}
                                  />
                                  {provided.placeholder}
                                </Box>
                                <Divider />
                              </React.Fragment>
                            )}
                          </Draggable>
                        );
                      })}{" "}
                    </Box>
                  )}
                </Droppable>
              </DragDropContext>
              {projectFields.videoMedia.map((video, index) => {
                return (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        width: "30%",
                        height: "100px",
                        marginTop: "5%",
                      }}
                    >
                      <video
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                        src={video}
                        alt={video}
                        controls
                      />
                    </Box>
                    <img
                      onClick={() => {
                        handleDelete(video, "video");
                      }}
                      style={{ marginTop: "5%", cursor: "pointer" }}
                      height="15vh"
                      src={closeIcon}
                      alt={closeIcon}
                    />
                  </Box>
                );
              })}
              {projectFields.audioMedia.map((audio, index) => {
                return (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ width: "30%", height: "100px" }}>
                      <audio
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                        src={audio}
                        alt={audio}
                        controls
                      />
                    </Box>
                    <img
                      onClick={() => {
                        handleDelete(audio, "audio");
                      }}
                      style={{ marginTop: "5%", cursor: "pointer" }}
                      height="15vh"
                      src={closeIcon}
                      alt={closeIcon}
                    />
                  </Box>
                );
              })}

            </div>
          </div>
          <div>
            <label className="title-input">Description</label>

            <Editor
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName description-input "
              editorClassName="editorClassName editor-edit"
              editorState={editorState}
              onEditorStateChange={onSetEditorState}
              onChange={(e) => {
                onhandleEditorState(editorState, "description");
              }}
            />
          </div>
          <button type="submit" className="button-post">
            {location.state && location.state.editprojectDetails
              ? "Preview"
              : "Preview"}
          </button>
        </form>
      )}
    </Card>
  );
};
const mapStateToProps = (state) => {
  return {
    upload1Response: state.projectReducer.upload1,
    upload2Response: state.projectReducer.upload2,
    loadingMediaResponse: state.projectReducer.loadingMedia,
    loadingDocResponse: state.projectReducer.loadingDoc,
    removeMediaResponse: state.projectReducer.removeMedia,
    editResponse: state.projectReducer.edit,
    createResponse: state.projectReducer.create,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    postProject: (projectFields) =>
      dispatch({ type: "CREATE_PROJECT", value: projectFields }),
    upload1: (file) => dispatch({ type: "UPLOAD1", value: file }),
    upload2: (file) => dispatch({ type: "UPLOAD2", value: file }),
    editProject: (projectFields) =>
      dispatch({ type: "EDIT_PROJECT", value: projectFields }),
    projectUpdate: (projectFields) =>
      dispatch({ type: "UPDATE_IMPACT_PROJECT", value: projectFields }),
    listAllProjects: (page, type) =>
      dispatch({ type: "PROJECT_LIST", value: { type: type, page: page } }),
    loadingMedia: (loader) =>
      dispatch({ type: "LOADING_MEDIA", value: loader }),
    loadingDocs: (loader) => dispatch({ type: "LOADING_DOC", value: loader }),
    removeMedia: (media) => dispatch({ type: "REMOVE_MEDIA", value: media }),
    removeUploadContent: () =>
      dispatch({ type: "REMOVE_UPLOAD_CONTENT", value: "" }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProjectForm);
