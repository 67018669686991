import React, { useState } from "react";
import { TableRow, TableCell, Button, ListItemText, Box } from "@mui/material";
import { colorPicker } from "../../constants/constants";
import { useStyles } from "../projectListContainer/projectListContainerStyle";
import DonorProfile from "../donorProfile/donorProfile";
import { connect } from "react-redux";
import approveImage from "../../assets/images/approve-image.png";
import blockImage from "../../assets/images/block-image.png";
import { blockUserMessage } from "../../constants/constants";
import { approveBlogMessage } from "../../constants/constants";
import { useNavigate } from "react-router-dom";
let title = "";
let status = "";
let image = "";
let userData = {};
const DonorContainer = ({
  data,
  ngoImage,
  index,
  listAllDonors,
  approveNgo,
}) => {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const handleApprove = (action) => {
    if (modal) {
      setModal(!modal);
      approveNgo(userData);
      listAllDonors();
      userData = {};
      navigate("/dashboard/details", { state: { header: "Total Donors" } });
    } else {
      if (action === "approve" || action === "verified") {
        image = approveImage;
        title = approveBlogMessage;
      } else {
        image = blockImage;
        title = blockUserMessage;
      }
      userData = data;
      userData.action = action;
      status = "approve";
      setModal(!modal);
      console.log("modal", modal);
    }
  };
  const [dialog, setDialog] = useState(false);
  const classes = useStyles();
  return (
    <React.Fragment>
      {dialog ? (
        <DonorProfile
          handleApprove={handleApprove}
          status={status}
          image={image}
          title={title}
          data={data}
          dialog={dialog}
          setDialog={setDialog}
          modal={modal}
          setModal={setModal}
          listAllDonors={listAllDonors}
        />
      ) : (
        ""
      )}
      <TableRow
        className={classes.projectRow}
        key={data.name}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
          background: index % 2 === 0 ? "#ffff" : "#FBFBFB",
        }}
      >
        <TableCell sx={{ display: "flex", fontSize: "1vw" }} align="left">
          <img
            style={{ borderRadius: "100%", marginRight: "5%" }}
            width="50vw"
            height="50vh"
            src={data.profilePic || ngoImage}
            alt={data.profilePic || ngoImage}
          />
          <ListItemText
            disableTypography
            sx={{
              fontWeight: "bold",
              textTransform: "uppercase",
              fontSize: "1vw",
            }}
          >
            {data.name}
          </ListItemText>
        </TableCell>
        <TableCell
          sx={{ textTransform: "uppercase", fontSize: "1vw" }}
          align="left"
        >
          <strong>{data.contactNumber}</strong>
        </TableCell>
        <TableCell
          sx={{ textTransform: "uppercase", fontSize: "1vw" }}
          align="left"
        >
          <strong>{data.emailAddress}</strong>
        </TableCell>
        <TableCell
          sx={{ textTransform: "uppercase", fontSize: "1vw" }}
          align="left"
        >
          <strong>{data.user_id}</strong>
        </TableCell>
        <TableCell sx={{ fontSize: "1vw" }} align="left">
          <Box
            sx={{
              background: colorPicker(
                data.status === "verified" ? "Yes" : "No"
              ),
              color: "#ffffff",
              textAlign: "center",
              padding: "5% 0% 5% 0%",
              width: "4vw",
              alignItems: "center",
              borderRadius: "30px",
              display: "flex",
              fontSize: "1vw",
              fontWeight: "bold",
              alignContent: "center",
            }}
          >
            <img
              style={{
                borderRadius: "100%",
                marginRight: "5%",
                marginLeft: "5%",
              }}
              src={
                "/" +
                (data.status === "verified" ? "verified" : "rejected") +
                ".png"
              }
              alt={
                "/" +
                (data.status === "verified" ? "verified" : "rejected") +
                ".png"
              }
            />
            {data.status === "verified" ? "Yes" : "No"}
          </Box>
        </TableCell>
        <TableCell
          sx={{ textTransform: "uppercase", fontSize: "1vw" }}
          align="left"
        >
          <Button
            onClick={() => {
              setDialog(!dialog);
            }}
            sx={{
              border: "1px solid #9CA0AF",
              borderRadius: "42px",
              color: "black",
              padding: "2%",
              fontSize: "1vw",
              background: "#fffff",
            }}
          >
            VIEW
          </Button>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    deleteResponse: state.usersReducer.delete,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    listAllDonors: () => dispatch({ type: "DONOR_LIST" }),
    approveNgo: (userData) =>
      dispatch({ type: "APPROVE_NGO", value: userData }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DonorContainer);
