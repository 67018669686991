import { takeLatest, put, call } from "redux-saga/effects";
import axios from "axios";
import { BASE_URL } from "../../constants/constants";

function* createProjectAsync(request) {
  try {
    const { data } = yield call(() => {
      return axios.request({
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        method: "post",
        url: BASE_URL + "/blogs/create",
        data: {
          title: request.value.title,
          cause: request.value.cause,
          startDate: request.value.startDate,
          endDate: request.value.endDate,
          description: request.value.description,
          details: request.value.details,
          type: request.value.type || "project",
          videoMedia: request.value.videoMedia,
          audioMedia: request.value.audioMedia,
          imageMedia: request.value.imageMedia,
          otherMedia: request.value.otherMedia,
          amountNeeded: request.value.amountNeeded,
          donationDetails: request.value.donationDetails,
          status: "pending",
        },
      });
    });
    yield put({ type: "CREATE_PROJECT_ASYNC", value: data });
  } catch (e) {
    alert("create project failed");
  }
}

function* editProjectAsync(request) {
  try {
    const { data } = yield call(() => {
      return axios.request({
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        method: "put",
        url: BASE_URL + "/blogs/edit",
        data: {
          b_id: request.value.b_id,
          title: request.value.title,
          cause: request.value.cause,
          startDate: request.value.startDate,
          endDate: request.value.endDate,
          description: request.value.description,
          details: request.value.details,
          type: request.value.type || "project",
          otherMedia: request.value.otherMedia,
          videoMedia: request.value.videoMedia,
          audioMedia: request.value.audioMedia,
          imageMedia: request.value.imageMedia,
          amountNeeded: request.value.amountNeeded,
          donationDetails: request.value.donationDetails,
          status: request.value.status,
        },
      });
    });
    yield put({ type: "EDIT_PROJECT_ASYNC", value: data });
  } catch (e) {
    alert("edit project failed");
  }
}

function* deleteProjectAsync(request) {
  try {
    const { data } = yield call(() => {
      return axios.request({
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        method: "delete",
        url: BASE_URL + "/blogs/delete",
        data: {
          b_id: request.value,
        },
      });
    });
    yield put({ type: "DELETE_PROJECT_ASYNC", value: data });
  } catch (e) {
    alert("delete project failed");
    console.log(e.message);
  }
}

function* createUpdateOrImpactAsync(request) {
  try {
    const { data } = yield call(() => {
      return axios.request({
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        method: "post",
        url: BASE_URL + "/blogs/" + request.value.type,
        data: {
          imageUpdate: request.value.imageUpdate,
          type: request.value.type,
          lastUpdate: request.value.lastUpdate,
          title: request.value.title,
          b_id: request.value.b_id,
        },
      });
    });
    console.log("dat", data);
    yield put({ type: "CREATE_UPDATE_IMPACT_PROJECT_ASYNC", value: data });
    yield put({ type: "LOADING_MEDIA", value: false });
  } catch (e) {
    alert("Create", request.value.type, "failed");
    console.log(e.message);
  }
}

function* getProjectUpdateOrImpactAsync(request) {
  try {
    const { data } = yield call(() => {
      return axios.request({
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        method: "post",
        url: BASE_URL + "/blogs/getUpdatesOrImpacts",
        data: {
          updateOrImpact: request.value.data,
        },
      });
    });
    if (request.value.tab === "Update") {
      console.log("Entered the update tab");
      yield put({ type: "UPDATE_PROJECT_ASYNC", value: data });
    } else {
      yield put({ type: "IMPACT_PROJECT_ASYNC", value: data });
    }
  } catch (e) {
    alert("update project failed");
    console.log(e.message);
  }
}

function* listProjectAsync(request) {
  console.log(request.value);
  try {
    const { data } = yield call(() => {
      return axios.request({
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        method: "get",
        url: BASE_URL + `/blogs/listProjects`,
        params: {
          page: request.value.page || 1,
          type: request.value.type,
        },
      });
    });
    yield put({ type: "PROJECT_LIST_ASYNC", value: data });
    yield put({ type: "LOADING_MEDIA", value: false });
  } catch (e) {
    console.log(e.message);
  }
}

function* uploadAsync(request) {
  try {
    const formData = new FormData();
    formData.append("file", request.value);
    const { data } = yield call(() => {
      return axios.request({
        headers: {
          "content-type": "multipart/form-data",
        },
        method: "post",
        url: BASE_URL + "/blogs/upload",
        data: formData,
      });
    });
    let UPLOAD_ASYNC;
    console.log(request.type);
    if (request.type === "UPLOAD1") {
      yield put({ type: "LOADING_MEDIA", value: false });
      UPLOAD_ASYNC = "UPLOAD1_ASYNC";
    } else {
      yield put({ type: "LOADING_DOC", value: false });
      UPLOAD_ASYNC = "UPLOAD2_ASYNC";
    }
    yield put({ type: UPLOAD_ASYNC, value: data });
  } catch (e) {
    alert("could not upload. Please try again");
  }
}
function* changeProjectStatusAsync(request) {
  console.log("request before approval", request);
  try {
    yield call(() => {
      return axios.request({
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        method: "put",
        url: BASE_URL + "/blogs/approveBlogs",
        data: {
          action: request.value.status,
          id: request.value._id,
          feedback: request.value.feedback,
        },
      });
    });
  } catch (e) {
    alert("approve project failed");
    console.log(e.message);
  }
}
function* removeMediaAsync(request) {
  console.log("request before approval", request);
  try {
    const { data } = yield call(() => {
      return axios.request({
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        method: "delete",
        url: BASE_URL + "/blogs/removeMedia",
        data: {
          src: request.value.src,
          type: request.value.type,
          id: request.value.bId,
          documentType: request.value.documentType,
        },
      });
    });
    yield put({ type: "REMOVE_MEDIA_ASYNC", value: data });
  } catch (e) {
    alert("delete media failed");
    console.log(e.message);
  }
}
function* searchAsync(request) {
  try {
    console.log("-", typeof request.value.type);
    let url =
      request.value.type === "blogs" ? "/blogs/search" : "/users/searchUsers";
    const { data } = yield call(() => {
      return axios.request({
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        method: "get",
        url: BASE_URL + url,
        params: {
          term: request.value.term,
          type: request.value.type,
          page: request.value.page,
        },
      });
    });
    switch (request.value.type) {
      case "blog":
        yield put({ type: "PROJECT_LIST_ASYNC", value: data });
        break;
      case "NGO":
        yield put({ type: "NGO_LIST_ASYNC", value: data });
        break;
      case "donor":
        yield put({ type: "DONOR_LIST_ASYNC", value: data });
        break;
      default:
        yield put({ type: "PROJECT_LIST_ASYNC", value: data });
    }
  } catch (e) {
    console.log(e.message);
  }
}
function* editImpactOrUpdateAsync(request) {
  try {
    console.log("request value editorimpact", request.value);
    yield call(() => {
      return axios.request({
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        method: "put",
        url: BASE_URL + "/blogs/editUpdatesOrImpacts",
        data: {
          updateObject: request.value,
        },
      });
    });
  } catch (e) {
    console.log(e.message);
  }
}

export function* watchProject() {
  yield takeLatest("CREATE_PROJECT", createProjectAsync);
  yield takeLatest("PROJECT_LIST", listProjectAsync);
  yield takeLatest("UPLOAD1", uploadAsync);
  yield takeLatest("UPLOAD2", uploadAsync);
  yield takeLatest("EDIT_PROJECT", editProjectAsync);
  yield takeLatest("DELETE_PROJECT", deleteProjectAsync);
  yield takeLatest("CREATE_UPDATE_IMPACT_PROJECT", createUpdateOrImpactAsync);
  yield takeLatest("GET_PROJECT_UPDATE_IMPACT", getProjectUpdateOrImpactAsync);
  yield takeLatest("CHANGE_PROJECT_STATUS", changeProjectStatusAsync);
  yield takeLatest("REMOVE_MEDIA", removeMediaAsync);
  yield takeLatest("SEARCH", searchAsync);
  yield takeLatest("EDIT_IMPACT_UPDATE", editImpactOrUpdateAsync);
}
