import { Dialog, DialogContent } from "@mui/material";
import arrowLeft from "../../assets/images/left-arrow.png";
import arrowRight from "../../assets/images/right-arrow.png";
import React from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useState } from "react";
import "../projectDetailsContainer/projectDetailsContainer.scss"
const PdfViewer = ({ pdfmodal, setPdfModal, otherMedia,fileSelected }) => {
  const [numPages, setNumPages] = useState(null);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const [pageNumber, setPageNumber] = useState(1);
  console.log("Entered", pdfmodal);
  const handleClose = () => {
    setPdfModal(!pdfmodal);
  };
  const handleNext = () => {
    if (pageNumber < numPages) setPageNumber(pageNumber + 1);
  };
  const handlePrev = () => {
    if (pageNumber > 1) setPageNumber(pageNumber - 1);
  };
  return (
    <Dialog
      sx={{
        "& .MuiPaper-root": {
          width: "60vw",
          maxWidth: "none",
          height: "100vh",
        },
      }}
      onClose={handleClose}
      open={pdfmodal}
    >
      <DialogContent
        sx={{
          position: "relative",
          padding: "3%",
          background: "black",
          overflowY: "hidden",
          textAlign:"center"
        }}
      >
        <img
          onClick={() => {
            handlePrev();
          }}
          style={{
            top: "50%",
            position: "absolute",
            cursor: "pointer",
            height: "5vh",
            left: "1%",
            display: pageNumber > 1 ? "flex" : "none",
          }}
          src={arrowLeft}
          alt={arrowLeft}
        />
              <Document
                onLoadSuccess={onDocumentLoadSuccess}
                onClick={() => {
                  setPdfModal(!pdfmodal);
                }}
                renderMode="canvas"
                file={fileSelected}
              >
                <Page className="pdfviewer" 
                  height={600}
                  canvasBackground="#ffff"
                  pageNumber={pageNumber}
                />
              </Document>
        <img
          onClick={() => {
            handleNext();
          }}
          style={{
            position: "absolute",
            top: "50%",
            right: "1%",
            cursor: "pointer",
            height: "5vh",
            display: pageNumber < numPages ? "flex" : "none",
          }}
          src={arrowRight}
          alt={arrowRight}
        />
        <p style={{ color: "white",fontSize:"1.5vw" }}>
        <strong>  Page {pageNumber} of &nbsp;{numPages}</strong>
        </p>
      </DialogContent>
    </Dialog>
  );
};
export default PdfViewer;
