import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  loginButton: {
    marginTop: "5vh",
    background: "#FAC11C",
    borderRadius: "12px",
    textTransform: "capitalize",
    color: "black",
    padding: "2.5%",
    "&:hover": {
      backgroundColor: "#F9C11D",
      opacity: 0.9,
    },
  },
  loginInput: {
    border: "1px solid #CACACA",
    borderRadius: "6px",
    background: "#FFFFFF",
    "& .Mui-error":{
        color:"red"
    }
  },
});
