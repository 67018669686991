import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/login/login.jsx";
import { history } from "./helpers/history";
import React from "react";
import Dashboard from "./pages/dashboard/dashboardHome/dashboardHome";
import Projects from "./pages/projects/listProjects/listProjects";
import CreateProject from "./pages/projects/createProject/createProject";
import DashboardDetails from "./pages/dashboard/dashboardDetails/dashboardDetails";
import ProjectDetails from "./pages/projects/projectDetails/projectDetails";
import UpdateProject from "./pages/projects/updateProject/updateProject";
import CreateImpactProject from "./pages/projects/createImpactProject/createImpactProject";
import Profile from "./pages/profile/profile"
import Rewards from "./pages/rewards/rewards";
import DonationHistory from "./pages/donationhistory/donationHistory";
import NewsArticles from "./pages/newsarticles/newsArticles";
import PledgedList from "./pages/pledgedlist/pledgedList"; 
import MediaLibrary from "./pages/medialibrary/mediaLibrary";
import BankDetails from "./pages/bankdetails/bankDetails";
import Lottie from 'react-lottie';
import pagenotfound from './assets/images/404.json';
import CreateNews from "./pages/newsarticles/createNews";
import NewsDetails from "./pages/newsarticles/newsDetails";
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: pagenotfound,
  renderer: 'svg'
}
class App extends React.Component {
  render() {
    return (
      <div className="app">
        <BrowserRouter history={history}>
          {history.location.pathname === "/" ? history.push("/login") : ""}
          <Routes>
            <Route exact path="/login" element={<Login />} />
            <Route path="/dashboard" element={<Dashboard/>} />
            <Route path="/userlist" element={<DashboardDetails active="User List" donorTab={true}/>} />
            <Route path="/rewards" element={<Rewards/>} />
            <Route path="/donationhistory" element={<DonationHistory/>} />
            <Route path="/newsarticles" element={<NewsArticles/>} />
            <Route path="/newsarticles/create" element={<CreateNews/>} />
            <Route path="/pledgedlist" element={<PledgedList/>} />
            <Route path="/medialibrary" element={<MediaLibrary/>} />
            <Route path="/bankdetails" element={<BankDetails/>} />
            <Route path="*" element={<div><Lottie
                options={defaultOptions}
                height={500}
                width={500}
               /></div>} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/projects/create" element={<CreateProject />} />
            <Route path="/projects/update" element={<UpdateProject />} />
            <Route path="/projects/impact" element={<CreateImpactProject />} />
            <Route path="/dashboard/details" element={<DashboardDetails />} />
            <Route path="/dashboard/profile" element={<Profile/>} />
            <Route path="/projects/:id" element={<ProjectDetails />} />
            <Route path="/newsArticles/:id" element={<NewsDetails/>} />
          </Routes>
        </BrowserRouter>
      </div>
    );
  }
}
export default App;