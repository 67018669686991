import arrowLeft from "../../assets/images/left-arrow.png";
import arrowRight from "../../assets/images/right-arrow.png";
import { useEffect, useState } from "react";
import { DialogContent, Dialog } from "@mui/material";
const MediaPopup = (props) => {
  const { modal, setModal, images, videos } = props;
  const [mediaArray, setMediaArray] = useState(images);
  useEffect(() => {
    setMediaArray([...mediaArray, ...videos]); // eslint-disable-next-line
  }, []);
  const [index, setIndex] = useState(0);
  console.log(mediaArray.length);
  const handleClose = () => {
    setModal(!modal);
  };
  const handleNext = () => {
    if (index < mediaArray.length - 1) {
      setIndex(index + 1);
    }
  };
  const handlePrev = () => {
    if (index > 0) {
      setIndex(index - 1);
    }
  };
  return (
    <Dialog
      sx={{
        "& .MuiPaper-root": {
          width: "60vw",
          maxWidth: "none",
          height: "100vh",
        },
      }}
      onClose={handleClose}
      open={modal}
    >
      <DialogContent
        sx={{
          position: "relative",
          padding: "5% 9% 5% 9%",
          background: "black",
          overflowY: "hidden",
        }}
      >
        <img
          onClick={() => {
            handlePrev();
          }}
          style={{
            top: "50%",
            position: "absolute",
            cursor: "pointer",
            height: "5vh",
            left: "1%",
            display: index > 0 ? "flex" : "none",
          }}
          src={arrowLeft}
          alt={arrowLeft}
        />
        {index < images.length ? (
          <img
          style={{width:"49vw",height: "80vh" }}
            src={mediaArray[index]}
            alt={mediaArray[index]}
          />
        ) : (
          <video
            src={mediaArray[index]}
            alt={mediaArray[index]}
            style={{width:"49vw",height: "80vh" }}
            controls
          ></video>
        )}
        <img
          onClick={() => {
            handleNext();
          }}
          style={{
            position: "absolute",
            top: "50%",
            right: "1%",
            cursor: "pointer",
            height: "5vh",
            display: index < mediaArray.length - 1 ? "flex" : "none",
          }}
          src={arrowRight}
          alt={arrowRight}
        />
      </DialogContent>
    </Dialog>
  );
};
export default MediaPopup;
