import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  approveStatus: {
    background: "#E4F8D1",
    borderRadius: "57px",
    padding: "0.5% 1% 0.5% 1%",
    fontWeight: 500,
    fontSize: "1vw",
    textAlign: "center",
    color: "#61B40E",
    textTransform: "uppercase",
  },
  rejectStatus: {
    background: "#FFDDD9",
    borderRadius: "57px",
    padding: "0.5% 1% 0.5% 1%",
    fontWeight: 500,
    fontSize: "1vw",
    textAlign: "center",
    color: "#F24E3A",
    textTransform: "uppercase",
  },
  approveButton: {
    padding: "0.5% 1% 0.5% 1%",
    borderRadius: "43px",
    width: "7vw",
    background: "#61B40E",
    color: "#fff",
    fontSize: "1vw",
    textAlign: "center",
    cursor: "pointer",
    position: "absolute",
    marginLeft: "55%",
    textTransform: "initial",
    "&:hover": {
      background: "#61B40E",
      opacity: 0.9,
    },
  },
  rejectButton: {
    padding: "0.5% 2% 0.5% 2%",
    borderRadius: "43px",
    width: "7vw",
    background: "#F24E3A",
    color: "#fff",
    fontSize: "1vw",
    textAlign: "center",
    cursor: "pointer",
    marginLeft: "65%",
    position: "absolute",
    textTransform: "initial",
    "&:hover": {
      background: "#F24E3A",
      opacity: 0.9,
    },
  },
  audio: {
    display: "flex",
    margin: "5% 0% 3% 3%",
    width: "60%",
    justifyContent: "space-between",
  },
  newButton: {
    background: "#F9C11D",
    borderRadius: "7px",
    minWidth: "max-content",
    padding: "1% 5% 1% 5%",
    "&:hover": {
      backgroundColor: "#F9C11D",
      opacity: 0.9,
    },
  },
  course: {
    // width: "15vw",
    minWidth:"max-content",
    blockSize: "fit-content",
    fontSize: "0.9vw",
    textTransform: "lowercase",
    background: "#FFF2CD",
    padding: "3%",
    borderRadius: "44px",
    margin: "0% 2% 0% 2%",
    textAlign: "center",
  },
});
