import { spawn } from "redux-saga/effects";
import { watchProject } from "./project";
import { watchLogin } from "./login";
import { watchUser } from "./users";
import { watchTransactions } from "./transactions";

export default function* rootSaga() {
  yield spawn(watchLogin);
  yield spawn(watchProject);
  yield spawn(watchUser);
  yield spawn(watchTransactions);
}
