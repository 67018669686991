import { useStyles } from "./loginStyle.js";
import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import "../login/login.scss";
import loginbckimg from "../../assets/images/Loginview2.png";
import { useNavigate } from "react-router-dom";
import spinner from "../../assets/images/loadingSpinner.json";
import Lottie from "react-lottie";
import logo from "../../assets/images/logo3.png";

import {
  Box,
  Button,
  Link,
  TextField,
  Typography,
  Grid,
  Checkbox,
} from "@mui/material";
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: spinner,
  renderer: "svg",
};
const Login = (props) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const classes = useStyles();
  const [error, setError] = useState(false);
  const [autoComplete, setAutocomplete] = useState(true);
  useEffect(() => {
    if (props.loginResponse.message) {
      setError(true);
    }
    const token = localStorage.getItem("token");
    if (token && token !== "undefined") {
      navigate("/dashboard");
    }
    if (props.loginResponse.results) {
      if (token && token !== "undefined") {
        navigate("/dashboard");
      }
      localStorage.setItem("token", props.loginResponse.results.token);
      if (autoComplete) {
        localStorage.setItem("email", email);
        localStorage.setItem("password", password);
      } else {
        localStorage.removeItem("email", email);
        localStorage.removeItem("password", password);
      }
      navigate("/dashboard");
    }
  }, [props, navigate, email]);

  const handleSubmit = (e) => {
    e.preventDefault();

    props.loadingMedia(true);
    props.Auth(email, password);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          position: "absolute",
          top: "0px",
          left: "0px",
          backgroundImage: "url(" + loginbckimg + ")",
          width: "100%",
          height: "100%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "cover",
          backgroundSize: "100vw 100vh",
        }}
      ></Box>
      <Box sx={{ position: "absolute", left: "35%", top: "8%" }}>
        <img
          width="35%"
          height="35%"
          style={{ margin: "0% 0% 7% 32%" }}
          src={logo}
          alt={logo}
        />
        <Typography sx={{ color: "#7E7E7E" }} align="center" variant="h6">
          Welcome back
        </Typography>
        <Typography
          sx={{ fontWeight: "500", marginBlock: "3%" }}
          align="center"
          variant="h4"
        >
          Login to your account
        </Typography>
        <Typography align="center" variant="subtitle1">
          Please enter your Email and Password
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            type="email"
            error={error}
            required
            className={classes.loginInput}
            inputProps={{ style: { fontSize: "1vw", border: "black" } }}
            variant="outlined"
            value={email}
            onChange={(e) => {
              setError(false);
              setEmail(e.target.value);
              props.logOut();
            }}
            placeholder="Email ID"
            fullWidth
            style={{ marginTop: 20, border: "0" }}
          />

          <TextField
            type="password"
            error={error}
            required
            onFocus={() => {
              if (localStorage.getItem("email") === email) {
                setPassword(localStorage.getItem("password"));
              }
            }}
            className={classes.loginInput}
            inputProps={{ style: { fontSize: "1vw" } }}
            variant="outlined"
            fullWidth
            value={password}
            onChange={(e) => {
              setError(false);
              setPassword(e.target.value);
              props.logOut();
            }}
            placeholder="Password"
            style={{ marginTop: 20, border: "0" }}
          />
          {error ? (
            <Typography
              variant="subtitle1"
              align="center"
              sx={{
                marginTop: "5%",
                color: "#FA2E2E",
                borderRadius: "55px",
              }}
            >
              {props.loginResponse.message}
            </Typography>
          ) : (
            ""
          )}
          {props.loadingMediaResponse ? (
            <Lottie
              style={{ marginTop: "5%" }}
              options={defaultOptions}
              height={50}
              width={50}
            />
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <p
                    sx={{
                      fontWeight: "400",
                      fontSize: "16px",
                      cursor: "pointer",
                      fontFamily: "Poppins",
                    }}
                  >
                    <Checkbox
                      value={autoComplete}
                      checked={autoComplete}
                      onChange={() => {
                        setAutocomplete(!autoComplete);
                      }}
                      sx={{
                        color: "#F9C11D",
                        "&.Mui-checked": {
                          color: "#F9C11D",
                        },
                        paddingLeft: "0",
                      }}
                    />
                    Remember Password
                  </p>
                </Box>
                <Box></Box>
              </Box>
              <Button type="submit" fullWidth className={classes.loginButton}>
                Login
              </Button>
            </>
          )}
        </form>
      </Box>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    loginResponse: state.loginReducer.results,
    loadingMediaResponse: state.projectReducer.loadingMedia,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    Auth: (email, password) =>
      dispatch({ type: "LOGIN", value: { email: email, password: password } }),
    loadingMedia: (loader) =>
      dispatch({ type: "LOADING_MEDIA", value: loader }),
    logOut: () => dispatch({ type: "LOGOUT" }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
