import React from "react";
import bellIcon from "../../assets/images/bell.svg";
import profile from "../../assets/images/profile.jpeg";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./header.scss";
const Header = (props) => {
  const { loginResponse } = props;
  const navigate = useNavigate();
 
  return (
    <div className="header-container">
      <div className="header-main">{props.heading}</div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    projectListResponse: state.projectReducer.results,
    loginResponse: state.loginReducer.results,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logOut: () => dispatch({ type: "LOGOUT" }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
