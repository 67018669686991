import React, { useEffect, useState } from "react";
import Search from "../search/search";
import { dashboardProjectHeading } from "../../constants/constants";
import {
  Box,
  ListItemText,
  TableCell,
  TableRow,
  TableHead,
  Paper,
  Table,
  TableContainer,
  TableBody,
  ListItemIcon,
  Button,
  ListItemAvatar,
} from "@mui/material";
import placeholder from "../../assets/images/image-placeholder.jpg";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import more from "../../assets/images/more.png";
import deleteImage from "../../assets/images/delete-menu.png";
import block from "../../assets/images/block.png";
import imagePlaceholder from "../../assets/images/image-placeholder.jpg";
import { useNavigate } from "react-router-dom";
import { colorChange } from "../../constants/constants";
import { useStyles } from "./projectListContainerStyle";
import rejectImage from "../../assets/images/reject-image.png";
import approveImage from "../../assets/images/approve-image.png";
import DeleteImage from "../../assets/images/delete-image.png";
import DialogBox from "../dialogBox/confirmationDialog";
import { connect } from "react-redux";
import {
  rejectBlogMessage,
  approveBlogMessage,
  deleteProjectMessage,
} from "../../constants/constants";
let title = "";
let status = "";
let image = "";

const ProjectListContainer = (props) => {
  const classes = useStyles();
  const [modal, setModal] = useState(false);
  const [projectData, setProjectData] = useState();
  const [feedback, setFeedback] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    console.log("Enterd");
    setAnchorEl(event.currentTarget);
  };
  const handleDelete = () => {
    status = "";
    if (modal) {
      setModal(!modal);
      props.deleteProject(projectData.b_id);
      let timer = setTimeout(() => {
        props.listAllProjects(props.page, "project");
        navigate("/projects");
      }, 200);
      return () => clearTimeout(timer);
    } else {
      image = DeleteImage;
      setModal(!modal);
      title = deleteProjectMessage;
    }
  };
  const projectStatusChange = (action) => {
    if (modal) {
      setModal(!modal);
      projectData.status = status;
      projectData.feedback = feedback;
      props.projectStatusChange(projectData);
      let timer = setTimeout(() => {
        props.listAllProjects(props.page, "project");
      }, 200);
      return () => clearTimeout(timer);
    } else {
      if (action === "approved") {
        image = approveImage;
        title = approveBlogMessage;
      } else {
        image = rejectImage;
        title = rejectBlogMessage;
      }
      status = action;
      setModal(!modal);
    }
  };
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/login");
    }
  }, [navigate]);
  const { projectList } = props;
  return (
    <React.Fragment>
      {modal ? (
        <DialogBox
          status={status}
          feedback={feedback}
          setFeedback={setFeedback}
          submit={status !== "" ? projectStatusChange : handleDelete}
          handleDelete={handleDelete}
          image={image}
          title={title}
          modal={modal}
          setModal={setModal}
        />
      ) : (
        ""
      )}
      <Box className={classes.searchContainer}>
        <Button
          sx={{ color: "black" }}
          onClick={() => {
            navigate("/projects/create");
          }}
          className={classes.newButton}
        >
          +
        </Button>
        <Box>Add new need</Box>
        <Search page={props.page} type="blogs" />
      </Box>
      <TableContainer className={classes["&.MuiPaper-root"]} component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ background: "#F5F5F5" }}>
              {dashboardProjectHeading.map((header, index) => {
                return (
                  <TableCell sx={{ fontSize: "0.9vw" }} key={index}>
                    {header}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {projectList.map((data, index) => {
              return (
                <TableRow
                  key={data.title}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <DialogBox
                    status={status}
                    feedback={feedback}
                    setFeedback={setFeedback}
                    submit={status !== "" ? projectStatusChange : handleDelete}
                    handleDelete={handleDelete}
                    image={image}
                    title={title}
                    modal={modal}
                    setModal={setModal}
                  />
                  <TableCell
                    onClick={() => {
                      navigate("/projects/" + data.b_id, {
                        state: { projectDetails: data },
                      });
                    }}
                    sx={{
                      display: "flex",
                      fontSize: "0.9vw",
                      cursor: "pointer",
                      width: "24vw",
                    }}
                    align="left"
                  >
                    <img
                      style={{ borderRadius: "0%", marginRight: "5%" }}
                      width="90vw"
                      height="70vh"
                      src={data.imageMedia[0]}
                      alt={data.imageMedia[0]}
                    />
                    <Box sx={{ display: "grid", rowGap: "1px" }}>
                      <ListItemAvatar
                        disableTypography
                        sx={{
                          fontWeight: "bold",
                          textTransform: "uppercase",
                          fontSize: "1vw",
                          width: "20vw",
                        }}
                      >
                        {data.title}
                      </ListItemAvatar>
                      <ListItemText
                        disableTypography
                        sx={{
                          fontWeight: "bold",
                          textTransform: "uppercase",
                          color: "#717171",
                          fontSize: "0.9vw",
                        }}
                      >
                        {data.uniqueId}
                      </ListItemText>
                      <ListItemText
                        disableTypography
                        sx={{
                          fontWeight: "bold",
                          color: "#000000",
                          fontSize: "0.9vw",
                        }}
                      >
                        {data.lastModified}
                      </ListItemText>
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      textTransform: "uppercase",
                      fontSize: "0.9vw",
                      width: "17vw",
                    }}
                    align="left"
                  >
                    <Box sx={{ display: "grid" }}>
                      <ListItemText sx={{ fontSize: "1vw" }} disableTypography>
                        <strong>{data.authorName}</strong>
                      </ListItemText>
                      <ListItemText
                        sx={{ fontSize: "0.9vw" }}
                        disableTypography
                      >
                        <strong>{data.approvedDate}</strong>
                      </ListItemText>
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{ textTransform: "uppercase", fontSize: "1vw" }}
                    align="left"
                  >
                    <strong>Rs 0</strong>
                  </TableCell>
                  <TableCell
                    sx={{ textTransform: "uppercase", fontSize: "1vw" }}
                    align="left"
                  >
                    <strong>Rs 0</strong>
                  </TableCell>
                  <TableCell
                    sx={{ textTransform: "uppercase", fontSize: "1vw" }}
                    align="left"
                  >
                    <strong>Rs 0</strong>
                  </TableCell>
                  <TableCell
                    sx={{ textTransform: "uppercase", fontSize: "0.9vw" }}
                    align="left"
                  >
                    <Box
                      sx={{
                        background: colorChange(
                          data.status === "rejected"
                            ? data.status
                            : data.diffDays
                        )[0],
                        borderRadius: "30px",
                        textTransform: "uppercase",
                        color: "#ffffff",
                        textAlign: "center",
                        padding: "3% 0% 3% 0%",
                        alignItems: "center",
                        display: "flex",
                        fontSize: "0.8vw",
                        width: "8vw",
                      }}
                    >
                      <img
                        style={{
                          borderRadius: "100%",
                          marginRight: "5%",
                          marginLeft: "5%",
                        }}
                        src={
                          colorChange(
                            data.status === "rejected"
                              ? data.status
                              : data.diffDays
                          )[1]
                        }
                        alt={
                          colorChange(
                            data.status === "rejected"
                              ? data.status
                              : data.diffDays
                          )[1]
                        }
                      />
                      {data.status === "rejected" ? data.status : data.diffDays}
                    </Box>
                  </TableCell>
                  <TableCell sx={{ fontSize: "1vw" }} align="left">
                    {console.log(typeof data.daysLeft)}
                    <strong>
                      {data.daysLeft <= 0
                        ? (data.hoursLeft || 0) + "Hrs"
                        : data.daysLeft + "Days"}
                    </strong>
                  </TableCell>
                  <TableCell align="left">
                    <img
                      style={{ cursor: "pointer" }}
                      onClick={handleClick}
                      src={more}
                      alt={more}
                    />
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      autoFocus={false}
                    >
                      {data.status === "rejected" ? (
                        <MenuItem
                          onClick={() => {
                            projectStatusChange("approve");
                            handleClose();
                          }}
                          sx={{ fontSize: "0.9vw" }}
                        >
                          <ListItemIcon>
                            <img src={block} alt={block} />
                          </ListItemIcon>
                          Approve
                        </MenuItem>
                      ) : (
                        <MenuItem
                          onClick={() => {
                            projectStatusChange("block");
                            handleClose();
                          }}
                          sx={{ fontSize: "1vw" }}
                        >
                          <ListItemIcon>
                            <img src={block} alt={block} />
                          </ListItemIcon>
                          Block
                        </MenuItem>
                      )}
                      <MenuItem
                        sx={{ fontSize: "1vw" }}
                        onClick={() => {
                          setAnchorEl(null);
                          handleDelete();
                        }}
                      >
                        <ListItemIcon>
                          <img src={deleteImage} alt={deleteImage} />
                        </ListItemIcon>
                        Delete
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              );
            })}
            {/* {projectList.length<1 ? (
              <Box sx={{alignItems:"center"}}>
              <Lottie options={defaultOptions} height={500} width={500} /></Box>
            ) : (
              ""
            )} */}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    deleteProject: (id) => dispatch({ type: "DELETE_PROJECT", value: id }),
    projectStatusChange: (data) =>
      dispatch({ type: "CHANGE_PROJECT_STATUS", value: data }),
    listAllProjects: (page, type) =>
      dispatch({ type: "PROJECT_LIST", value: { page: page, type: type } }),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectListContainer);
