import Box from "@mui/material/Box";
import backButton from "../../assets/images/back.svg";
import Typography from "@mui/material/Typography";
import phoneIcon from "../../assets/images/phone.png";
import { Button } from "@mui/material";
import DialogBox from "../dialogBox/confirmationDialog";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import approveImage from "../../assets/images/approve-image.png";
import {
  deleteProjectMessage,
  CreateProjectMessage,
  EditProjectMessage,
} from "../../constants/constants";
let status = "";
let title = "";
let image = "";
const UpdateImpactPreview = ({
  projectFields,
  preview,
  setPreview,
  createdProject,
  heading,
}) => {
  console.log("bid", createdProject);
  const [modal, setModal] = useState(false);
  const navigate = useNavigate();
  const handleSubmit = (event) => {
    event.preventDefault();
    if (modal) {
      if (projectFields) {
        setModal(!modal);
        navigate("/projects/" + createdProject.blog.b_id, {
            state: {
              projectDetails: createdProject.blog,
            },
        })
      } else {
        setModal(!modal);
        navigate("/projects/" + createdProject.blog.b_id, {
            state: {
              projectDetails: createdProject.blog,
            },
        })
      }
    } else {
      setModal(!modal);
      title = projectFields ? EditProjectMessage : CreateProjectMessage;
      image = approveImage;
      status = "approve";
    }
  };
  return (
    <Box>
      <DialogBox
        status={status}
        submit={status !== "" ? handleSubmit : ""}
        image={image}
        title={title}
        modal={modal}
        setModal={setModal}
      />
      <form
        onSubmit={(e) => {
          handleSubmit(e);
        }}
        className="project-header3 left"
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <img
            className="back-button"
            onClick={() => {
              setPreview(false);
              navigate("/projects/" + heading, {
                state: {
                  projectDetails: createdProject.blog,
                  updateFields: createdProject.update,
                },
              });
            }}
            style={{ cursor: "pointer", height: "2vh", width: "1.2vw" }}
            src={backButton}
            alt={backButton}
          />
          <Typography sx={{ fontSize: "1.25vw", fontWeight: "bold" }}>
            {projectFields
              ? `Edit an ${heading}/Preview`
              : `Create an ${heading}/Preview`}
          </Typography>
        </Box>
        <div style={{ height: "100%", width: "100%" }}>
          <img
            style={{ margin: "5% 0% 5% 30%", width: "45%" }}
            src={phoneIcon}
            alt={phoneIcon}
          />
          <iframe
            style={{
              position: "absolute",
              left: "46.4%",
              top: "37%",
              border: "none",
              borderRadius: "12px",
              height: "78%",
              width: "19.6%",
            }}
            src={
              "https://ekj-webviewtest.web.app/#/" +
              localStorage.getItem("previewId")
            }
          ></iframe>
          <button
            type="submit"
            style={{ width: "18vw", marginLeft: "27vw" }}
            className="button-post"
          >
            {projectFields ? "Save" : "Post"}
          </button>
        </div>
      </form>
    </Box>
  );
};
export default UpdateImpactPreview;
