const initialState = {
  results: {},
  donors: [],
  delete:{}
};

const usersReducer = (state = initialState, action) => {
  const newState = { ...state };

  switch (action.type) {
    case "NGO_LIST_ASYNC":
      newState.results = action.value;
      break;
    case "DONOR_LIST_ASYNC":
      newState.donors = action.value;
      break;
      case "DELETE_NGO_ASYNC":
        newState.delete = action.value;
        break;
    default:
      return newState;
  }
  return newState;
};

export default usersReducer;
