import Navbar from "../../../components/navbar/navbar";
import Header from "../../../components/header/header";
import React from "react";
import { connect } from "react-redux";
import UpdateOrImpactForm from "../../../components/projectForm/updateOrImpactForm";
import {Card} from "@mui/material"

class UpdateProject extends React.Component {
  constructor(props) {
    super(props);
    if (!this.props.loginResponse.results) {
      this.props.auth();
    }
    this.state = {
      title: "",
      description: "",
      imageMedia: "",
    };
  }
  render() {
    return (
      <div>
        <Navbar active="Projects" />
        <Header heading="HOME/PROJECTS" />
        <Card
          sx={{
            margin: "0% 0% 0% 11.7%",
            padding: "0% 3% 9% 5%",
            border: "1px solid #E5E5E5",
            borderRadius: "15px",
          }}
        >
        <UpdateOrImpactForm heading="Post an Update" type="update" {...this.state} />
        </Card>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginResponse: state.loginReducer.results,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    auth: () => dispatch({ type: "AUTH" }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UpdateProject);

