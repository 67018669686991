import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  block: {
    background: "#F24E3A",
    borderRadius: "22px",
    color: "#FFFFFF",
    padding: "1% 5% 1% 5%",
    "&:hover": {
      opacity: 0.9,
      background: "#F24E3A",
    },
    textTransform: "capitalize",
    marginTop: "5%",
    marginBottom: "5%",
  },
  tab: {
    textTransform: "initial",
    fontSize: "0.9vw",
    boxShadow: "none",
    margin: "0% 4% 0% 0%",
    padding: "0%",
    width: "max-content",
    color: "#362816",
    minWidth: "0px",
    "&.Mui-selected": {
      color: "#362816",
      fontWeight: "bold",
    },
  },
  tabContainer: {
    borderBottom: "1px solid #EBEBEB",
  },
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#FAC11C",
      height: "0.4vh",
    },
  },

  unblock: {
    background: "#61B40E",
    borderRadius: "22px",
    color: "#FFFFFF",
    padding: "1% 5% 1% 5%",
    "&:hover": {
      opacity: 0.9,
      background: "#61B40E",
    },
    textTransform: "capitalize",
    marginTop: "5%",
    marginLeft: "5%",
    marginBottom: "5%",
  },
  dialog: {
    "& .MuiPaper-root": {
      width: "48vw",
      borderRadius: "0px",
      maxWidth: "none",
    },
    "& .MuiTableContainer-root": {
      width: "100%",
    },
  },
  list: {
    display: "flex",
    position: "relative",
    alignItems: "center",
  },
});
