import { takeLatest, put, call } from "redux-saga/effects";
import axios from "axios";
import { BASE_URL } from "../../constants/constants";

function* listNgoAsync(request) {
  let page = request.value || 1;
  try {
    const { data } = yield call(() => {
      return axios.request({
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        method: "get",
        url: BASE_URL + `/users/listNgo?page=${page}`,
      });
    });
    yield put({ type: "NGO_LIST_ASYNC", value: data });
    yield put({ type: "LOADING_MEDIA", value: false });
  } catch (e) {
    alert("User is not verified.Change url to /login and try again");
    console.log(e.message);
  }
}
function* listDonorAsync(request) {
  try {
    let page = request.value || 1;
    const { data } = yield call(() => {
      return axios.request({
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        method: "get",
        url: BASE_URL + `/users/listDonors?page=${page}`,
      });
    });
    yield put({ type: "DONOR_LIST_ASYNC", value: data });
    yield put({ type: "LOADING_MEDIA", value: false });
  } catch (e) {
    alert("Server API is incomplete");
    console.log(e.message);
  }
}

function* approveNgoAsync(request) {
  try {
    console.log("request", request);
    yield call(() => {
      return axios.request({
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        data: {
          id: request.value._id,
          action: request.value.action,
        },
        method: "post",
        url: BASE_URL + "/users/approveUser",
      });
    });
  } catch (e) {
    alert("Server API is incomplete");
    console.log(e.message);
  }
}

function* deleteNgoAsync(request) {
  try {
    console.log("request", request);
    const { data } = yield call(() => {
      return axios.request({
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        data: {
          userId: request.value._id,
        },
        method: "delete",
        url: BASE_URL + "/users/deleteNgo",
      });
    });
    console.log("---------", data);
    yield put({ type: "DELETE_NGO_ASYNC", value: data });
  } catch (e) {
    alert("Server API is incomplete");
    console.log(e.message);
  }
}

function* editNgoAsync(request) {
  try {
    console.log("request", request);
    const { data } = yield call(() => {
      return axios.request({
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        data: {
          id: request.value._id,
          name: request.value.name,
          age: request.value.age,
          pancard: request.value.pancard,
          email: request.value.emailAddress,
          country: request.value.country,
          location: request.value.location,
          gender: request.value.gender,
          address: request.value.address,
          city: request.value.city,
          pinCode: request.value.pinCode,
          state: request.value.state,
          contactNumber: request.value.contactNumber,
          registrationNumber: request.value.registrationNumber,
          status: request.value.status,
          type: request.value.type,
          profilePic: request.value.profilePic,
          typeOfNGO: request.value.typeOfNGO,
          foundersName: request.value.foundersName,
          bankName: request.value.bankName,
          nameOfAccountHolder: request.value.nameOfAccountHolder,
          accountNumber: request.value.accountNumber,
          ifscCode: request.value.ifscCode,
          branch: request.value.branch,
          gpayNumber: request.value.gpayNumber,
          paytmNumber: request.value.paytmNumber,
          accountsHeadName: request.value.accountsHeadName,
          accountsHeadContact: request.value.accountsHeadContact,
          communucationHeadName: request.value.communucationHeadName,
          communucationHeadContact: request.value.communucationHeadContact,
          title: request.value.title,
          dateOfIncorporation: request.value.dateOfIncorporation,
          fcraRegNumber: request.value.fcraRegNumber,
          focusedCourses: request.value.focusedCourses,
          gstNumber: request.value.gstNumber,
          regionalOfficeAddress: request.value.regionalOfficeAddress,
          taxRegistration: request.value.taxRegistration,
          AAABCertification: request.value.AAABCertification,
          founderContactNumber: request.value.founderContactNumber,
          goals: request.value.goals
        },
        method: "put",
        url: BASE_URL + "/users/editUser",
      });
    });
    console.log("---------", data);
  } catch (e) {
    alert("Server API is incomplete");
    console.log(e.message);
  }
}
function* ngoCommentsAsync(request) {
  try {
    console.log("request", request);
    const { data } = yield call(() => {
      return axios.request({
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        data: {
          _id: request.value._id,
          comments: request.value.comments,
          date: request.value.date,
        },
        method: "post",
        url: BASE_URL + "/users/addComment",
      });
    });
    console.log("---------", data);
    yield put({ type: "DELETE_NGO_ASYNC", value: data });
  } catch (e) {
    alert("Server API is incomplete");
    console.log(e.message);
  }
}

export function* watchUser() {
  yield takeLatest("NGO_LIST", listNgoAsync);
  yield takeLatest("APPROVE_NGO", approveNgoAsync);
  yield takeLatest("EDIT_NGO", editNgoAsync);
  yield takeLatest("DONOR_LIST", listDonorAsync);
  yield takeLatest("DELETE_NGO", deleteNgoAsync);
  yield takeLatest("NGO_COMMENTS", ngoCommentsAsync);
}
