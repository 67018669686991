import { takeLatest,call,put } from "redux-saga/effects";
import axios from "axios";
import { BASE_URL } from "../../constants/constants";
function* loginAsync(request) {
  try {
    const { data } = yield call(() => {
      return axios.request({
        headers: {
          "Content-Type": "application/json",
        },
        method: "get",
        url: BASE_URL+"/login",
        params: {
          email: request.value.email,
          password: request.value.password,
        }
      });
    });
    yield put({ type: "LOGINASYNC", value: data });
    yield put({ type: "LOADING_MEDIA", value: false });
  } catch (e) {
    alert("404 status error",e);
  }
}

function* authAsync(request) {
  try {
    const { data } = yield call(() => {
      return axios.request({
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        method: "get",
        url: BASE_URL+"/auth",
      });
    });
    yield put({ type: "LOGINASYNC", value: data });
  } catch (e) {
    alert("404 status error",e);
  }
}

export function* watchLogin() {
  yield takeLatest("LOGIN", loginAsync);
  yield takeLatest("AUTH", authAsync);
}