import Header from "../../components/header/header";
import React from "react";
import Navbar from "../../components/navbar/navbar";
import { connect } from "react-redux";
import { Card } from "@mui/material";

import NewsDetailsContainer from "../../components/newsDetails/newsDetailsContainer";

class NewsDetails extends React.Component {
  constructor(props) {
    super(props);
    if (!this.props.loginResponse.results) {
      this.props.auth();
    }
  }
  render() {
    return (
      <div>
        <Navbar active="News Articles" />
        <Header heading="HOME/NEWS" />
        <Card
          sx={{
            margin: "0% 0% 0% 11.8%",
            padding: "0% 3% 9% 3%",
            border: "1px solid #E5E5E5",
            borderRadius: "15px",
          }}
        >
          <NewsDetailsContainer />
        </Card>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginResponse: state.loginReducer.results,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    auth: () => dispatch({ type: "AUTH" }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(NewsDetails);
