import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  ListItemIcon,
  Button,
  Tab,
  Tabs,
} from "@mui/material";
import { Box } from "@mui/system";
import ngoImage from "../../assets/images/profile-image";
import editIcon from "../../assets/images/edit.png";
import { useStyles } from "./donorProfileStyle";
import closeIcon from "../../assets/images/close.png";
import {
  donorDialogFields,
  donorPopupTabs,
  DonorEditFields,
} from "../../constants/constants";
import lockIcon from "../../assets/images/lock-icon.png";
import React, { useState, useEffect } from "react";
import ConfirmationDialog from "../dialogBox/confirmationDialog";
import DonorEdit from "./donorEdit";
import { colorPicker } from "../../constants/constants";
import DonorTransactions from "./donorTransactions";
const DonorProfile = ({
  dialog,
  setDialog,
  data,
  status,
  title,
  image,
  handleApprove,
  handleDelete,
  modal,
  setModal,
  listAllDonors,
}) => {
  const classes = useStyles();
  const [edit, setEdit] = useState(false);
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setEdit(false)
  };
  const [tabselector, setTabselector] = React.useState(donorPopupTabs);
  return (
    <React.Fragment>
      <ConfirmationDialog
        submit={status === "approve" ? handleApprove : handleDelete}
        image={image}
        title={title}
        modal={modal}
        setModal={setModal}
      />

      <Dialog
        className={classes.dialog}
        open={dialog}
        onClose={() => {
          setDialog(!dialog);
        }}
        
      >
        <DialogContent
          sx={{
            padding: "5% 5% 0% 10%",
            height: "100vh",
            overflowX:"hidden",
          }}
        >
          <ListItemIcon
            sx={{
              display: "flex",
              flexDirection: "row-reverse",
              marginBottom: "5%",
            }}
          >
            <img
              src={closeIcon}
              alt={closeIcon}
              style={{ cursor: "pointer", width: "1vw", height: "2vh" }}
              onClick={() => {
                setDialog(!dialog);
              }}
            />
          </ListItemIcon>
          <DialogActions
            sx={{ WebkitJustifyContent: "flex-start", padding: "0%" }}
          >
            <img
              style={{ borderRadius: "100%", marginRight: "5%" }}
              width="60vw"
              height="60vh"
              src={data.profilePic || ngoImage}
              alt={data.profilePic || ngoImage}
            />
            <Box>
              <Typography
                sx={{
                  fontSize: "1vw",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  color: "#23190C",
                  width: "8vw",
                }}
              >
                {data.name}
              </Typography>
              <Box
                sx={{
                  background: colorPicker(data.status),
                  borderRadius: "30px",
                  padding: "2% 10% 2% 10%",
                  color: "white",
                  width: "max-content",
                  fontSize: "0.9vw",
                  textTransform: "capitalize",
                }}
              >
                {data.status}
              </Box>
              {/* <Typography sx={{ fontSize: "0.9vw", color: "#23190C" }}>
                Updated 1 day ago
              </Typography> */}
            </Box>
            <Typography sx={{ fontSize: "1vw", paddingLeft: "40%" }}>
              Edit
            </Typography>
            <img
              onClick={() => {
                setEdit(true);
              }}
              style={{ cursor: "pointer" }}
              height="19vh"
              width="19vw"
              src={editIcon}
              alt={editIcon}
            />
          </DialogActions>
          {data.status === "verified" ? (
            <Button
              onClick={() => handleApprove("block")}
              className={classes.block}
            >
              <img src={lockIcon} alt={lockIcon} />
              &nbsp; Block
            </Button>
          ) : (
            <Button
              onClick={() => handleApprove("approve")}
              className={classes.unblock}
            >
              Unblock
            </Button>
          )}
          <Box sx={{ width: "100%" }}>
            <Box className={classes.tabContainer}>
              <Tabs
                className={classes.tabs}
                value={value}
                onChange={handleChange}
              >
                {tabselector.map((fields, index) => {
                  return (
                    <Tab key={index} className={classes.tab} label={fields} />
                  );
                })}
              </Tabs>
            </Box>
          </Box>
          {edit && value === 0 ? (
            <DonorEdit
              listAllDonors={listAllDonors}
              data={data}
              edit={edit}
              setEdit={setEdit}
            />
          ) : value === 0 ? (
            donorDialogFields.map((fields, index) => {
              return (
                <Box
                  sx={{
                    background: index % 2 === 0 ? "#fffff" : " #F9F9F9",
                    padding: "3% 2% 3% 2%",
                    position: "relative",
                  }}
                  className={classes.list}
                >
                  {fields[0]}
                  <Typography
                    sx={{
                      fontSize: "0.9vw",
                      fontWeight: "bold",
                      position: "absolute",
                      marginLeft: "35%",
                    }}
                  >
                    {data[`${fields[1]}`]}
                  </Typography>
                </Box>
              );
            })
          ) : (
            <DonorTransactions data={data} />
          )}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};
export default DonorProfile;
