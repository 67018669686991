import { Box } from "@mui/material";
import {
  NgoBankAccountFields,
  NgoDetailsHeading,
} from "../../constants/constants";
import Typography from "@mui/material/Typography";
import { ngoProfileStyles } from "./ngoProfileStyles";
import { useEffect, useState } from "react";
import NgoEdit from "./ngoEdit";
const NgoFields = ({ value, data, edit, setEdit, handleChange, page }) => {
  const [fieldSelector, setFieldSelector] = useState([]);
  useEffect(() => {
    console.log(value, fieldSelector);
    if (value === 0) {
      setFieldSelector(NgoDetailsHeading);
    } else {
      setFieldSelector(NgoBankAccountFields);
    }
  }, [fieldSelector, value]);
  const classes = ngoProfileStyles();
  return edit ? (
    <NgoEdit
      edit={edit}
      setEdit={setEdit}
      data={data}
      handleChange={handleChange}
      value={value}
      page={page}
    />
  ) : (
    <Box>
      {fieldSelector.map((fields, index) => {
        return (
          <Box
            key={index}
            sx={{
              background: index % 2 === 0 ? "#fffff" : " #F9F9F9",
            }}
            className={classes.list}
          >
            {fields[0]}
            <Typography
              sx={{
                fontSize: "0.9vw",
                fontWeight: "bold",
                marginLeft: "45%",
                position: "absolute",
              }}
            >
              {fields[0] === "SDG Goal"
                ? (data[`${fields[1]}`]+ ",").slice(0, -1)
                : data[`${fields[1]}`]}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};
export default NgoFields;
