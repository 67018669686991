import { Box, Input, Typography } from "@mui/material";
import React, { useState } from "react";
import { DatePicker } from "../datepicker/datepicker";
import { ngoProfileStyles } from "./ngoProfileStyles";
import { Button } from "@mui/material";
const NgoComments = (props) => {
  const classes = ngoProfileStyles();
  const [fields, setFields] = useState({
    date: new Date(),
    comments: "",
    _id: props.data._id,
  });
  const handleChange = (event, formFields) => {
    event.preventDefault();
    setFields((prevState) => ({
      ...prevState,
      [formFields]: event.target.value,
    }));
  };
  return (
    <React.Fragment>
      <Box sx={{ width: "11vw", fontSize: "0.9vw", marginTop: "5%" }}>
        <Typography sx={{ fontSize: "1vw", marginBottom: "10%" }}>
          DATE
        </Typography>
        <DatePicker
          onChange={(e) => {
            handleChange(e, "date");
          }}
          value={
            fields.date === new Date()
              ? ""
              : fields.date.toString().split("T")[0]
          }
        />
      </Box>
      <Typography sx={{ fontSize: "1vw", marginBottom: "3%", marginTop: "5%" }}>
        COMMENTS
      </Typography>
      <Input
        className={classes.text}
        inputProps={{ style: { fontSize: "0.9vw" } }}
        variant="outlined"
        fullWidth
        value={fields.comments}
        onChange={(e) => {
          handleChange(e, "comments");
        }}
        multiline
        placeholder="Enter a comment"
        rows={5}
        disableUnderline={true}
      />
      <Button
        className={classes.NgoSaveButton}
        onClick={() => {
          props.setDialog(!props.dialog);
          props.addComments(fields);
        }}
        fullWidth
        sx={{ background: "#FAC11C", borderRadius: "12px", marginTop: "5%" }}
      >
        SAVE
      </Button>
    </React.Fragment>
  );
};
export default NgoComments;
